@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');
.egmMapPage {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
}


/*.rct-checkbox {
    border-radius: 0;
    width: 25px;
    height: 25px;
    outline: auto;
    outline-color: #45a09f;
}*/


/* .react-checkbox-tree .rct-icon {
    display: inline;
    text-align: center;
    text-rendering: auto;
    font-family: "Font Awesome 5 Free", "FontAwesome", sans-serif;
    font-weight: normal;
    font-style: normal;
    -webkit-font-feature-settings: normal;
    font-feature-settings: normal;
    font-variant: normal;
}

.rct-collapse,
.rct-checkbox,
.rct-node-icon {
    padding: 0 5px;
    position: absolute;
    padding-right: 17px;
    padding-right: 22px;
    padding: 0;
    margin-right: 21px;
    margin-left: 351px;
}
*/

.rct-text label {
    display: inline-block;
    padding-right: 10px;
    white-space: nowrap;
}

.checkboxes input {
    vertical-align: middle;
}

.checkboxes label span {
    vertical-align: middle;
}

.slide-pane__overlay {
    position: fixed;
    top: 20%;
    left: 0px;
    right: 0px;
    bottom: unset;
    background-color: rgba(0, 0, 0, 0);
}

.slide-pane__content {
    padding: 0;
    overflow-y: visible;
}

.slide-pane__content br {
    display: none;
}

.slide-pane__header {
    display: none;
}

.ReactModalPortal .slide-pane_from_right {
    max-width: 450px;
}

@media (max-width: 575px) {
    .ReactModalPortal .slide-pane_from_right {
        max-width: 320px;
    }
}

.ReactModalPortal .slide-pane__overlay.ReactModal__Overlay--after-open {
    background-color: transparent;
    transition: background-color 0.5s;
}

.search_result>button {
    position: absolute;
    /* left: calc(100% - 87.1%); */
    top: 225px;
    right: -5%;
    background-color: #45A0A0;
    color: #fff;
}

header.MuiPaper-root {
    background-color: #77BDBD;
    padding: 0;
}

header.MuiPaper-root .MuiTabs-root button.MuiButtonBase-root.Mui-selected {
    background-color: #45A09F;
}

header.MuiPaper-root .MuiTabs-root button.MuiButtonBase-root {
    font-size: 18px;
    font-weight: 600;
    text-transform: capitalize;
    padding: 10px 50px;
    background: #77bdbd;
    border: none;
    outline: none;
}

.stop-x-scrolling {
    display:none;
}

@media (max-width: 575px) {
    header.MuiPaper-root .MuiTabs-root button.MuiButtonBase-root {
        padding: 10px 28px;
    }
}

.rct-node-parent ol li span.rct-title {
    font-weight: 200;
}

header.MuiPaper-root .MuiTabs-root button.MuiButtonBase-root:hover {
    border: none;
    background: #77bdbd;
    color: #fff;
}

.MuiBox-root-7 {
    padding: 40px 60px !important;
}

.MuiPaper-root {
    border: none !important;
}

.MuiPaper-root .MuiAccordionSummary-root {
    padding: 0;
    min-height: unset;
    background: #fff;
    border: none;
}

.MuiPaper-root .MuiAccordionSummary-root .MuiAccordionSummary-content {
    margin: 0;
}

.checkbox_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.rct-checkbox {
    display: block;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.MuiPaper-root .MuiAccordionSummary-root .MuiAccordionSummary-content p {
    width: 100%;
}

.checkbox_title h3 {
    max-width: 80%;
    flex: 0 0 80%;
    font-size: 22px;
    font-weight: 600;
}

.rct-node-icon {
    color: #33c;
    display: none;
}

.react-checkbox-tree ol .fa-chevron-up {
    margin-left: -650px;
}

.react-checkbox-tree ol ol .fa-chevron-up {
    margin-left: -610px;
}

.react-checkbox-tree ol .fa-chevron-down {
    margin-left: -650px;
}

.react-checkbox-tree ol ol .fa-chevron-down {
    margin-left: -610px;
}

.react-checkbox-tree .rct-icon {
    outline: none!important;
	width: 25px;			
}

.rct-icon-uncheck {
    border-radius: 0 !important;
    width: 25px!important;
    /* height: 25px!important;*/
    outline: auto!important;
    outline-color: #45a09f!important;
}

.rct-icon-check {
    border-radius: 0 !important;
    width: 25px!important;
    /* height: 25px!important;*/
    outline: none!important;
    outline-color: none!important;
}

.react-checkbox-tree ul {
    padding-left: 5px;
}

.rct-text {
    max-width: 80%;
    flex: 0 0 80%;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 10px;
    margin-left: 10px;
}

.rct-checkbox input {
    border-radius: 0;
    width: 25px;
    height: 25px;
    outline: auto;
    outline-color: #45a09f;
}

span#close {
    position: absolute;
    top: -9px;
    right: -20px;
    font-size: 16px !important;
    color: #000 !important;
    line-height: normal;
    border: 1px solid #aaa;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    font-weight: 700 !important;
    font-family: 'Open Sans', sans-serif !important;
}

.modalContentDefinition span#closeDefinition {
    position: absolute;
    top: -9px;
    right: -20px;
    font-size: 16px !important;
    color: #000 !important;
    line-height: normal;
    border: 1px solid #aaa;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    font-weight: 700 !important;
    font-family: 'Open Sans', sans-serif !important;
}

.modalContentNotification span#closeNotification {
    position: absolute;
    top: -9px;
    right: -20px;
    font-size: 16px !important;
    color: #000 !important;
    line-height: normal;
    border: 1px solid #aaa;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    font-weight: 700 !important;
    font-family: 'Open Sans', sans-serif !important;
}												  
.checkbox_title input {
    border-radius: 0;
    width: 25px;
    height: 25px;
    outline: auto;
    outline-color: #45a09f;
}

.MuiTypography-body1 {
    width: 100%;
}

.accordion_checkboxes ul li {
    /*display: flex;*/
    align-items: center;
    justify-content: space-between;
    list-style: none;
    margin-bottom: 15px;
}

.accordion_checkboxes ul li input {
    border-radius: 0;
    width: 25px;
    height: 25px;
    outline: auto;
    outline-color: #45a09f;
    margin-right: -86px
}

.MuiAccordionDetails-root {
    padding: 5px 0 0 30px !important;
}

.accordion_checkboxes ul li p {
    font-size: 20px;
    color: #888;
    /*max-width: 80%;*/
    flex: 0 0 80%;
    font-weight: 300;
}

.MuiAccordion-root {
    margin-bottom: 15px;
}

.rct-node-clickable {
    display: flex;
}

.rct-node-clickable:hover {
    font-weight: 600!important;
}

.tab_submit_btns {
    background-color: #485464;
    padding: 8px 60px;
    text-align: right;
    margin-top: 10px;
}

@media (max-width: 575px) {
    .tab_submit_btns {
        padding: 8px 20px;
    }
	.react-checkbox-tree ol .fa-chevron-up,
    .react-checkbox-tree ol .fa-chevron-down {
        margin-left: 0;
        position: absolute;
        left: -5px;
        top: 5px;
    }
    .react-checkbox-tree ol ol .fa-chevron-up,
    .react-checkbox-tree ol ol .fa-chevron-down {
        margin: 0;
    }										
}

.rct-icons-fa4 .rct-icon-uncheck::before {
    margin-left: -3px;
}

.tab_submit_btns button {
    padding: 3px 30px;
    font-size: 16px;
    background: inherit;
    border: none;
    color: #fff;
    margin-left: 5px;
}

.tab_submit_btns button.submit_btn {
    background-color: #45A09F;
}


/* chart */

#chartModal {
    max-height: 490px !important;
    transform: translate(-50%, -50%);
    top: 50% !important;
    left: 50% !important;
    background-color: #fff !important;
    height: fit-content !important;
}

#chartModalDefinition {
    max-height: 490px !important;
    transform: translate(-50%, -50%);
    top: 50% !important;
    left: 50% !important;
    background-color: #fff !important;
    height: fit-content !important;
}

#chartModalNotification {
    max-height: 490px !important;
    transform: translate(-50%, -50%);
    top: 50% !important;
    left: 50% !important;
    background-color: #fff !important;
    height: fit-content !important;
}							
							
.modalContent {
    margin: 0 !important;
    width: 100% !important;
    padding: 0 !important;
}

.modalContent,
.popup_right_section {
    display: grid;
    vertical-align: top;
}

.modalContentDefinition {
    margin: 0 !important;
    width: 100% !important;
    padding: 0 !important;
    display: grid;
    vertical-align: top;
}

.modalContentNotification {
    margin: 0 !important;
    width: 100% !important;
    padding: 0 !important;
    display: grid;
    vertical-align: top;
}
						  
.modalContentDefinition {
    background: #707070!important;
    border-radius: 8px;
    color: #ffffff!important;
    border: 1px dotted #707070;
}

.modalContentNotification {
    background: #ffffcc!important;
    border-radius: 8px;
    color: #ffffff!important;
    border: 1px dotted #707070;
						
}							   
#myModalDefinition,
.modalDefinition {
    border-radius: 15px;
}

#myModalNotification,
.modalNotification {
    border-radius: 15px;
}					 
.parent-level-0-odd {
    background-color: #dfdfdf;
    font-size: 13px!important;
    /* font-weight: 600!important; */
    font-family: 'Open Sans', sans-serif!important;
    color: #000000!important;
}

.parent-level-0-even {
    background-color: #c6c6c6;
    font-size: 13px!important;
    /* font-weight: 600!important; */
    font-family: 'Open Sans', sans-serif!important;
    color: #000000!important;
}

.parent-level-1-odd {
    background-color: #ffffff;
    font-family: 'Open Sans', sans-serif!important;
    font-size: 13px!important;
    font-weight: 400!important;
}

.parent-level-1-even {
    background-color: #ffffff;
    font-family: 'Open Sans', sans-serif!important;
    font-size: 13px!important;
    font-weight: 400!important;
}

.parent-level-2-odd {
    font-family: 'Open Sans', sans-serif!important;
    background-color: #f7f7f7;
    font-size: 13px!important;
    font-weight: 400!important;
}

.parent-level-2-even {
    font-family: 'Open Sans', sans-serif!important;
    background-color: #f7f7f7;
    font-size: 13px!important;
    font-weight: 400!important;
}

tr.outlevel1 th {
    font-weight: 500 !important;
}

.tbody.map_tbody>tr td svg {
    line-height: 0!important;
}

* .dropdown1 {
    outline: none !important;
}

.popup_right_section {
    /*max-width: 26%; */
    width: 60%;
    background-color: #F0F0F0;
}


/*.recordList ul li {
    font-size: 14px;
    text-align: left;
    padding: 15px;
    padding-bottom: 0;
    max-width: 100%;
    flex: 0 0 100%;
    margin: 0;
}*/

.recordList ul li {
    font-size: 14px;
    text-align: left;
    padding: 15px;
    padding-bottom: 0;
    max-width: 100%;
    flex: 0 0 100%;
    margin: 0;
    background-color: #F0F0F0;
}

.recordList ul li:hover {
    background-color: #fff;
}

.recordList ul {
    margin-bottom: 0;
    list-style: none;
    padding: 0;
    background-color: #ffffff;
}

.breadcrumb_part {
    height: max-content;
}

.recordList ul li:after {
    content: '';
    border-bottom: 1px solid #5DD2D2;
    display: block;
    margin-top: 10px;
}

.recordList {
    font-size: 13px;
    text-align: center;
    /* color: #455566; */
    margin: 0;
    /* background-color: #F0F0F0; */
    height: 380px;
    overflow: hidden;
    overflow-y: scroll;
    overflow-y: overlay;
}


/* toggle button side filter */

@media (max-width: 450px) {
    .side_close_btn {
        border-top-left-radius: 20px !important;
        border-top-right-radius: 20px !important;
        border-bottom-left-radius: 0 !important;
        top: -8% !important;
        left: 0 !important;
    }
}

button.side_filter_btn {
    border: none;
    /* background: #45a09f;
    color: #fff;*/
    padding: 4px 25px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    outline: none;
	z-index: 51;		   
}

.slide-pane__content button.side_filter_btn {
    border: none;
    background: #45a09f;
    color: #fff;
    padding: 14px 15px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    position: absolute;
    outline: none;
    top: 50%;
    z-index: 1;
    left: -51px;
    transform: translate(0, -50%);
}

.slide-pane__content button.side_filter_btn:before {
    display: none;
}

button.side_filter_btn:before {
    content: '\f00c';
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    right: 5px;
    bottom: 10px;
}

button.side_filter_btn i {
    margin-left: 0;
    font-size: 27px;
    font-weight: 400;
}


/* Tree */

.slide-pane__content .react-checkbox-tree ol li {
    margin-bottom: 20px;
}

.slide-pane__content span.rct-checkbox {
    position: static;
    margin: 0;
    outline: none;
}

.slide-pane__content button.rct-collapse {
    position: static;
    margin: 0;
    outline: none;
}

.slide-pane__content span.rct-text {
    max-width: 100%;
    flex: 0 0 100%;
    flex-direction: row-reverse;
    position: relative;
    justify-content: flex-end;
}

.slide-pane__content span.rct-node-clickable {
    max-width: 80%;
    flex: 0 0 80%;
    background: none!important;
    font-weight: 600!important;
	align-items: center;
}

.slide-pane__content span.rct-node-clickable:focus {
    background-color: #e2fdfd !important;
}

.slide-pane__content span.rct-title {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    margin-left: 10px;
}

.slide-pane__content span.rct-title {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    margin-left: 10px;
}

.slide-pane__content span.rct-text label:hover {
    background-color: #fff;
}

.slide-pane__content .MuiBox-root {
    max-height: 450px;
    overflow-y: scroll;
}

.slide-pane__content span.rct-text label {
    position: absolute;
    right: 0;
}

.change__order span#amount_sort {
    position: relative;
    background: #5DD2D2;
    padding: 15px 20px;
}

.change__order span#amount_sort #sort_options {
    position: absolute !important;
    z-index: 1;
    overflow: unset !important;
    z-index: 7;
    bottom: 52px;
    min-width: 150px;
    left: -90px;
    background-color: #fff !important;
}

.change__order span#amount_sort #sort_options ul {
    list-style: none;
    margin: 0;
    border: 1px solid #dfdfdf;
    padding: 0;
    background-color: #ffffff;
}

.change__order span#amount_sort #sort_options ul ul {
    border: none;
    width: 100%;
}

.change__order span#amount_sort #sort_options ul li {
    color: #45A0A0;
    font-size: 13px;
    padding: 5px 10px;
    max-width: 100%;
    flex: 0 0 100%;
    margin: 0;
}

.change__order span#amount_sort #sort_options ul li:hover {
    background-color: #f0f0f0;
    padding: 5px 10px;
}

/* .td-boxes {
  width: 120px;
  height: 120px;
} */

				  
#download {
    /* margin-left:1315px; */
}

/* @media (max-width: 1024px) {
    .search_result>button {
        left: calc(100% - 95%);
    }
} */

/* @media (max-width: 575px) {
    .search_result>button {
        left: calc(100% - 48px);
    }
} */

/* Scrolling Specific */



/* width */
#foreignObjectMain div::-webkit-scrollbar {
  width: 20px;
}

/* Track */
#foreignObjectMain div::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
#foreignObjectMain div::-webkit-scrollbar-thumb:horizontal {
  background: #45A09F; 
  border-radius: 10px;
 
 background: linear-gradient(270deg, rgba(255,255,255,1) 0%, rgba(69,160,159,1) 52%, rgba(69,160,159,1) 100%);
}


/* Handle */
#foreignObjectMain div::-webkit-scrollbar-thumb:vertical  {
  background: #45A09F; 
  border-radius: 10px;
 background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(69,160,159,1) 52%, rgba(69,160,159,1) 100%);
}

/* Handle on hover */
#foreignObjectMain div::-webkit-scrollbar-thumb:horizontal:hover {
  background: #328383; 
  background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(69,160,159,1) 52%, rgba(69,160,159,1) 100%);

}

/* Handle on hover */
#foreignObjectMain div::-webkit-scrollbar-thumb:vertical:hover {
  background: #328383; 
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(69,160,159,1) 52%, rgba(69,160,159,1) 100%);

}


/* 
@media (max-width: 575px) {
    #foreignObjectLegends td>div {
        width: 50%;
        text-align: left;
    }
}

@media (max-width: 450px) {
    #foreignObjectLegends td>div {
        width: 55%;
        text-align: left;
    }
}
*/




		
