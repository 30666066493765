@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');
.search_loader {
    text-align: center;
}

.css-yk16xz-control {
    width: 206px;
    height: auto;
    overflow-y: scroll;
    overflow: hidden;
}

.search_dropdown ul button {
    /*position: absolute; */
    margin-left: 0;
    bottom: 35px;
    left: 26%;
    margin-right: 0;
    padding: 0;
    width: 100%;
	border-radius: 5px;							   
}

th.th_Level.\33 \.4,
th.th_Level.\33 \.3 {
    /* background-color: white !important;*/
}

.css-1wa3eu0-placeholder {
    font-size: 14px;
    color: #000 !important;
    left: 0% !important;
	padding-top: 4px;			  
}

.banner_tab ul li:empty {
    display: none;
}

.search_dropdown ul li select {
    font-size: 14px;
	border-radius: 5px;					   
}

span.css-1okebmr-indicatorSeparator {
    display: none;
}

.css-tlfecz-indicatorContainer {
    padding: 0;
}

.css-tlfecz-indicatorContainer:after {
    color: #000;
    content: '\f0d7';
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 14px;
}

.css-tlfecz-indicatorContainer svg {
    display: none;
}

span.css-1okebmr-indicatorSeparator {
    display: none;
}

.css-tlfecz-indicatorContainer {
    padding: 0 !important;
    margin-top: 2px;
}

.search_dropdown ul li .dropdown1 {
    min-height: 31px;
    height: auto;
}

.search_dropdown ul {
    align-items: center;
    position: relative;
}

.search_top_filter {
    padding: 10px!important;
    /* margin: 20px 2px 20px 20px !important; */
}

.filter_condition_note {
    padding-bottom: 20px!important;
    padding-left: 20px!important;
    padding-top: 0px!important;
	margin-top:-12px!important;						
}

.filter_condition_note li {
    width: 100% !important;
    max-width: 100% !important;
    flex: 0 0 100% !important;
    margin-bottom: 0px !important;
}

.filter_condition_note li label {
    font-size: 12px !important;
    font-style: italic !important;
}

.css-1hb7zxy-IndicatorsContainer .css-tlfecz-indicatorContainer:first-child {
    display: none;
}

.css-g1d714-ValueContainer {
    padding: 0;
    display: flex;
    max-height: unset;
}

.css-12jo7m5 {
    font-size: 13px!important;
    padding-left: 2px !important;
    /* max-width: 78%;
    flex: 0 0 79%; */
}
.css-xb97g8{
    flex: 0 0 0;
}


/* @media only screen and (max-width: 1400px) {
    .search_dropdown ul li {
        flex: 0 0 16.3%;
        max-width: 16.3%;
    }
}

@media only screen and (max-width: 1400px) {
    .search_dropdown ul li {
        flex: 0 0 18.3%;
        max-width: 18.3%;
    }
} */

@media (max-width: 575px) {
    .search_dropdown ul li {
        max-width: 100%;
        flex: 0 0 100%;
		margin-top: 7px;
		margin-bottom: 5px;		  
    }
    .search_dropdown ul button {
        max-width: 180px;
        top: 0;
        margin: 0 auto;
    }

    .legend-list-items {
        display: block !important;
        flex: 0 0 100%;
        max-width: 100%;
    }
}

table: {
    border: 1px solid #45A09F;
    border-collapse: collapse;
    text-align: center;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
}

td: {
    border: 1px solid #45A09F;
    border-collapse: collapse;
    text-align: center;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
}

th: {
    border: 1px solid #45A09F;
    border-collapse: collapse;
    text-align: center;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
}


/* Modal Content (image) */

.modalContent: {
    margin: auto;
    display: flex;
    width: 80%;
    max-width: 700px;
}

.modalContentDefinition: {
    margin: auto;
    display: flex;
    width: 80%;
    max-width: 700px;
}

.modalContentNotification: {
    margin: auto;
    display: flex;
    width: 80%;
    max-width: 700px;
}							
.close: {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
}

.closeDefinition: {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
}

.closeNotification: {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
}					
.egmMapPage {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
}

th.intervention_outcome_label,
.outlevel3>th:first-child {
    background-color: #45A09F;
    color: #fff;
    padding: 5px;
    font-size: 20px;
    font-weight: 600;
    text-transform: capitalize;
    transform: unset;
    text-align: center;
}

tr.outlevel1 th:nth-child(odd) {
    /*  background-color: #eaeaea;*/
}

tr.outlevel1 th {
    padding: 5px;
    font-size: 18px;
    font-weight: 600;
}

/* .outlevel1 .parent-level-0-even {
    border-left: 1px solid rgb(69, 160, 159);
    border-right: 1px solid rgb(69, 160, 159);
} */

/* .outlevel2 .parent-level-1-even {
    border-left: 1px solid rgb(69, 160, 159);
    border-right: 1px solid rgb(69, 160, 159);
} */

/* .outlevel3 .parent-level-2-even {
    border-left: 1px solid rgb(69, 160, 159);
} */

/* tr.outlevel1 th {
    border-bottom: 1px solid rgb(69, 160, 159);
} */

tr.outlevel2 th {
    background-color: #fff;
    padding: 5px;
    font-size: 15px;
    font-weight: 300;
    color: #000000;
    text-align: center;
}

tr.outlevel3 th {
    /* transform: rotate(-90deg); */
    padding: 5px;
    font-size: 15px;
    font-weight: 300;
    color: #000000;
    text-align: center;
}

tr.outlevel1 th:nth-child(even) {
    /* background-color: #d2d2d2; */
}


/* 
tbody.map_tbody>tr:nth-child(odd) th:first-child {
    background-color: #C6C6C6;
    padding: 0 10px;
    font-size: 18px;
    font-weight: 600;
    text-transform: capitalize;
}

tbody.map_tbody>tr:nth-child(even) th:first-child {
    background-color: #DFDFDF;
    padding: 0 10px;
    font-size: 20px;
    font-weight: 600;
    text-transform: capitalize;
}

tr.row1 th:nth-child(2), 
tr.row4 th:first-child, 
tr.row8 th:nth-child(2), 
tr.row11 th:first-child, 
tr.row13 th:nth-child(2), 
tr.row15 th:first-child, 
.row17 th:nth-child(2), 
.row19 th:first-child, 
tr.row20 th:nth-child(2) {
    transform: rotate(-90deg);
    font-size: 15px !important;
    font-weight: 300 !important;
    color: #888;
    background-color: unset !important;
    padding: 0 0 !important;
    width: 80px;
    height: 100px;
}

tr.row1 th:nth-child(3),
tr.row2 th:first-child,
tr.row3 th:first-child,
tr.row4 th:nth-child(2),
tr.row5 th:first-child,
tr.row6 th:first-child,
tr.row7 th:first-child,
tr.row8 th:nth-child(3),
tr.row9 th:first-child,
tr.row10 th:first-child,
tr.row11 th:nth-child(2),
tr.row12 th:first-child,
tr.row13 th:nth-child(3),
tr.row14 th:first-child,
tr.row15 th:nth-child(2),
tr.row16 th:first-child,
tr.row17 th:nth-child(3),
tr.row18 th:first-child,
tr.row19 th:nth-child(2),
tr.row20 th:nth-child(3),
tr.row21 th:first-child,
tr.row22 th:first-child {
    padding: 5px 10px;
    text-align: center;
    width: 120px;
    color: #888;
    font-weight: 400 !important;
    background-color: unset !important;
    font-size: 13px !important;
} */

thead.map_thead tr .header_count {
    /* border: 1px solid #45A09F; */
    padding: 5px 30px !important;
    font-weight: 600;
    font-size: 16px;
}

thead.map_thead tr .header_count p {
    color: #45a09f;
    font-size: 30px;
    font-weight: 600;
    margin: 0;
}

.egmMapPage i {
    font-family: 'FontAwesome';
    font-style: normal;
}


/* breadcrumb */

.breadcrumb_part {
    background-color: #485464;
}

.breadcrumb_part>div {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #fff;
    padding: 20px;
}

.breadcrumb_part>div h6,
.breadcrumb_part>div i,
.breadcrumb_part>div p {
    font-size: 14px;
    color: #fff;
    margin: 0;
}

.breadcrumb_part>div i {
    margin: 0 10px;
}

.breadcrumb_part>div h6 {
    font-weight: bold;
}

.popup_right_section label {
    display: block;
    /*background-color: #B4D2EE;*/
    padding: 0 0;
    font-size: 20px;
    font-weight: bold;
    color: #455566;
    text-align: center;
    display: flex;
    margin: 0;
}

.popup_right_section h6 {
    font-size: 24px;
    text-align: center;
    color: #455566;
    margin: 30px 0;
}

.popup_right_section .change__order {
    background-color: #45A09F;
    display: flex;
    justify-content: space-between;
}

.popup_right_section .change__order i {
    color: #fff;
}

.change__order span#amount_sort {
    position: relative;
}

.change__order span#span_export {
    position: relative;
    background: #5DD2D2;
    padding: 15px 20px;
}

.some-custom-overlay-class {
    position: absolute !important;
    left: unset !important;
    right: 0 !important;
    /* top: calc(100% + 535px) !important;*/
    top: calc(100% + 535px);
    bottom: unset !important;
    z-index: 52;
}

.top_filter_btn {
    /* visibility: hidden; */
}

.drop_down {
    position: absolute;
    right: 0px;
    background: #fff;
    border: 1px solid #888;
    max-width: 300px;
    top: 45px;
    box-shadow: 0 0 20px 5px rgb(0 0 0 / .1);
    z-index: 55;
}

.drop_down p {
    color: #888;
    margin: 0;
    padding: 10px 0 10px 30px;
    font-size: 18px;
    border-bottom: 1px solid #45A09F;
}

.drop_down ul {
    background: #fff;
    padding: 15px 30px;
}

.drop_down ul li {
    margin: 0;
    display: block;
    max-width: unset;
    width: 100%;
    flex: unset;
    padding: 5px 0;
}

.drop_down ul li a {
    color: #888;
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
}

.download, .zoom_icon_minus, .zoom_icon_plus {
    padding: 0px 8px;
    font-size: 23px;
    line-height: normal;
    border: 1px solid #000;
    cursor: pointer;
    /*/ display: block; /*/
    /* margin-left: calc(100% - 40px); */
    margin-bottom: 6px;
    /*/ margin-left: auto; /*/
    width: 38px;
    background-color: #dfdfdf;
    float: right;
	border-radius: 4px;		
margin-left:10px;	
}


#zoom_icon_minus {
    background-color: #ffffff;
    border: none;
    color: #45a09f;
	margin-left: 0px;
}

#zoom_icon_plus {
    background-color: #ffffff;
    border: none;
    color: #cccccc;
	margin-left: 0px;
}



.search_result {
    padding: 6px 0!important;
    position: relative!important;
}

.spacing {
    display: block;
    width: 100%;
    background-color: #45A0A0;
}

div#chartZoom g.zoomNode:last-child {
    /* transform: translate(110px, 20px); */
}

.download-studies {
    position: absolute;
    right: 0;
    display: none;
    bottom: 53px;
    left: unset;
}

.popup_right_section {
    /* max-width: 26%; */
    width: 60%;
    background-color: #F0F0F0;
}

.change__order span#amount_sort #sort_options {
    position: absolute !important;
    z-index: 1;
    overflow: unset !important;
    z-index: 7;
    bottom: 52px;
    min-width: 150px;
    left: -90px;
    /* background-color: #fff !important; */
	background-color: transparent!important;
}


/*.download-studies{
    position: absolute;
    left: 573px;
    display: none;

}*/

.download-studies ul {
    background-color: #fff !important;
    padding: 0px;
    flex-direction: column;
    align-items: flex-start;
    border: 1px solid #dfdfdf;
}

.recordList ul li a {
    color: #455566;
    text-decoration: none;
}

.download-studies li {
    margin: 0 1px !important;
    width: 145px !important;
    max-width: 100% !important;
    cursor: pointer;
    padding: 3px 10px;
    /*border-bottom: 1px solid #D9D9D9;*/
}

.download-studies a {
    color: #45A0A0 !important;
    font-size: 13px !important;
    font-weight: 400;
    text-decoration: none;
}

.download-studies li:hover {
    background-color: #ececec;
    text-decoration: none;
}

tbody.map_tbody>tr.row17 th:first-child {
    /* background-color: #dfdfdf;*/
}

@media (max-width: 1537px) {
    .breadcrumb_part>div h6,
    .breadcrumb_part>div i,
    .breadcrumb_part>div p {
        font-size: 15px;
    }
}

@media (max-width: 1464px) {
    .breadcrumb_part>div h6,
    .breadcrumb_part>div i,
    .breadcrumb_part>div p {
        font-size: 14px;
    }
    .search_result #myModal {
        max-width: 700px;
        width: 100%;
    }
}

@media (max-width: 800px) {
    .search_result #myModal {
        max-width: 630px;
        width: 100%;
    }
}

@media (max-width: 767px) {
    .search_result #myModal {
        max-width: 520px;
        width: 100%;
    }
    .breadcrumb_part>div {
        padding: 10px;
    }
    .breadcrumb_part>div h6,
    .breadcrumb_part>div i,
    .breadcrumb_part>div p {
        font-size: 11px;
    }
    .recordList ul li {
        font-size: 13px;
    }

    #title-div {
        width: 100%;
    }
}

@media (max-width: 767px) {
    .modalContent,
    .popup_right_section {
        width: 100% !important;
        display: flex;
        flex-wrap: wrap;
    }
    #chartModal {
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }
    .breadcrumb_part,
    .popup_right_section label,
    .popup_right_section .change__order {
        width: 100%;
    }
    .change__order span#amount_sort #sort_options {
        left: 0;
    }
}

#title-div {
    background-color: #8DC1F1;
    align-items: center;
    display: flex;
    padding: 10px 5px;
}

#study_count {
    color: black;
    font-size: 14px;
    margin: 0 5px;
    background-color: white;
    padding: 5px 5px;
    border-radius: 10px;
    width: 35px;
    height: 35px;
    text-align: center;
    display: none;
    font-weight: 600;
    justify-content: center;
    align-items: center;
}

#main_map {
    background-color: white !important;
}

.print_button {
    cursor: pointer;
}

@page {
    size: auto;
    size: A0;
    margin: 1mm;
}


/*@media print {
    .no-print,
    .no-print * {
        display: none !important;
    }
    .show-print,
    .show-print * {
        display: inline-block !important;
    }
    .table td.print_bg,
    .table th.print_bg {
        background-color: #b2b2b2 !important;
    }
    .table td.print_bg2,
    .table th.print_bg2 {
        background-color: #f7f7f7 !important;
    }
    .badge {
        border-color: none !important;
        border: 1px solid #fff !important;
    }
    .table td .badge {
        border-color: none !important;
        border: 1px solid #fff !important;
    }
    .content-wrapper {
        background: #fff !important;
    }
    .search_dropdown{
        display: none !important;
    }
    #download{
        display: none !important;
    }
}*/

@media print {
    .no-print,
    .no-print * {
        display: none !important;
    }
    header {
        display: none !important;
    }
    footer {
        display: none !important;
    }
    .banner {
        display: none !important;
    }
    .banner {
        display: none !important;
    }
    #main_map,
    foreignObject,
    #map_table {
        width: 800px !important;
    }
    iframe {
        display: none !important;
    }
    .loadingWave,
    .side_filter_btn,
    #myModal,
    .button {
        display: none !important;
    }
}

table.map_table td, table.map_table th {
    box-shadow: 0px 0px 0px 0px #fffefe, 1px 0px 0px 0px #fffefe;
}


/*@media print {
  body * {
    visibility: hidden;
  }

  #main_map {
    display: inline-block !important;*/


/*position: absolute;*/


/*left: 0;
    top: 0;
  }
}*/


/*
tbody.map_tbody>tr:nth-child(odd) th:first-child, 
tbody.map_tbody>tr:nth-child(even) th:first-child {*/

tbody.map_tbody>tr th {
    padding: 2px 10px;
    text-align: center;
    color: #000000;
    font-weight: 400;
    font-size: 13px;
    min-height: 120px;
    align-items: center;
}

tbody.map_tbody>tr th:nth-child(1) {
    /* transform: rotate(-90deg); */
    /*max-width: 200px;*/
    /* padding: 10px; */
    /*padding: 0 59px;
    word-break: break-all;*/
    /* border: 1px solid #45A09F; */
}

tbody.map_tbody>tr th:nth-child(2) {
    /* transform: rotate(-90deg); */
    /* max-width: 150px;*/
    /* padding: 10px; */
    /* word-break: break-word;*/
    /* border: 1px solid #45A09F; */
}

tbody.map_tbody>tr th.parent-level-undefined:nth-child(2) {
    /* transform: rotate(-90deg);*/
    /* max-width: 150px;*/
    /* padding: 10px; */
    /* word-break: normal; */
}

tbody.map_tbody>tr th.th_Level {
    transform: none;
    padding: 10px;
    /* background-color: #f7f7f7;*/
}

tbody.map_tbody>tr th.parent-level-0:parent {
    background-color: #dfdfdf;
}

div.tooltip {
    position: absolute;
    text-align: center;
    width: 200px;
    padding: 6px;
    font: 12px sans-serif;
    background: #707070;
    border-radius: 8px;
    pointer-events: none;
    color: #ffffff;
    border: 1px dotted #707070;
}

#print_custom_note {
    width: 300px;
    right:42px;
}

#side_filter_custom_note {
    width: 300px;
    right:77px;
}

.circle_icon {
    background: #f00;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    color: #f00;
}

.box_icon {
    background: #f7f7f7;
    width: 18px;
    height: 18px;
    color: #f7f7f7;
}

.grid_bg {
    background: linear-gradient(-90deg, rgba(158, 158, 158, .5) 1px, transparent 1px), linear-gradient(rgba(158, 158, 158, .5) 1px, transparent 1px), #ffffff;
    background-size: 4px 4px, 4px 4px, 80px 80px, 80px 80px, 80px 80px, 80px 80px, 80px 80px, 80px 80px;
    font-size: 1px;
	/*border-right: 1px solid rgb(69, 160, 159);*/										   
}

.noselect_text {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.banner_tab .nav-tabs li a {
    cursor: pointer;
}

@media (max-width: 575px) {
    .banner_tab .nav-tabs li a {
        min-height: auto;
    }
}

.search_dropdown ul li .dropdown1 .css-tlfecz-indicatorContainer:last-child {
    display: none;
}

.search_dropdown ul li .dropdown1 .css-tlfecz-indicatorContainer:first-child {
    display: none;
}

.search_dropdown ul li .dropdown1>.css-tlfecz-indicatorContainer {
    display: block;
}

.search_dropdown ul li .dropdown1 span.css-1okebmr-indicatorSeparator+.css-tlfecz-indicatorContainer {
    display: block;
}

.search_dropdown ul li .dropdown1 .css-26l3qy-menu {
    font-size: 14px;
}

.egmMapPage .banner:before {
    content: '';
    background-color: black;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.4;
    z-index: 0;
}

.egmMapPage .banner .banner_content {
    position: relative;
}

.egmMapPage .banner {
    position: relative;
}

.egmMapPage .banner h1,
.egmMapPage .banner .modification_date p,
.banner_content .banner_body p,
.banner_description span,
.banner_description span p,
.egmMapPage .research_report a {
    text-shadow: 0px 0px 3px rgb(0 0 0 / 60%);
    filter: drop-shadow(0px 0px 100px black);
}

.egmMapPage .mobileBanner {
    display: none;
}

.egmMapPage .mobileBanner img {
    width: 100%;
}

@media screen and (max-width: 719px) {
    .egmMapPage .bannerHide {
        background: none !important;
    }

    .egmMapPage .mobileBanner {
        display: block;
    }
}

.project_funded_by img {
    max-width: 100%;
    height: auto;
}

.refresh_message {
    text-align: center;
    border: 1px solid #A13422;
    border-radius: 10px;
    padding: 15px;
    background: #f8f8f8;
    margin-top: 60px;
    font-weight: bold;
}

.refresh_button {
    background-color: #45a09f;
    border-radius: 5px;
    border: none;
    padding: 5px;
    width: 100px;
    color: #fff;
    cursor: pointer;
    font-weight: 600;
}

.custom_tooltip .__react_component_tooltip {
    background-color: #45A09F;
    color: #fff;
}

.custom_tooltip .__react_component_tooltip.type-dark.place-top:after {
    border-top-color: #45A09F;
    display: none !important;
}


/* .custom_tooltip .__react_component_tooltip.place-top:before {
    display: none !important;
} */
.c_tooltip:before,
.c_tooltip:after {
    display: none !important;
}

.c_tooltip {
    opacity: 1 !important;
    width: 250px;
}

.tooltip_img {
    margin-top: -3px;
}

@media (hover: none) {
    .filter_condition:hover {
        color: oldcolor;
    }
}


.recordList ul {
    max-width: 95%;
}

.search_dropdown ul li label {
    line-height: 1.0;
}

.search_top_filter {
    padding: 10px 5px 4px 5px!important;
}

/* thead.map_thead>tr th:nth-child(1) {
    width: 25% !important;
} */
/* .th_outcome_head { width: 16.6% !important;} */

/* .map_table {
    width: 100% !important;
} */
/* .map_table th.media {
    justify-content: center;
} */

.egmMapPage {
    zoom: 0.95;
}


#main_map {
	zoom: 0.85;
    -ms-zoom: 0.85;
	-webkit-zoom: 0.85;
	transform:  scale(1,1);
	-webkit-transform:  scale(1,1);
	-moz-transform:  scale(1,1);
	transform-origin: left top;
	-moz-transform-origin: left top;	
}
	
#zoom_icon_plus {
		color : #45a09f;
}

.search_dropdown ul li .dropdown1 {
    background-image: none;
}

@media only screen and (max-width: 1025px) {
	
	#main_map {
		zoom: 0.65;		
	}
	
	#zoom_icon_plus {
		color : #45a09f;
    }
}

@media only screen and (max-width: 992px) {
	
	#main_map {
		zoom: 0.55;		
	}
	
	#zoom_icon_plus {
		color : #45a09f;
    }
}


@media (max-width: 575px) {
	
	#main_map {
		zoom: 0.33;		
	}
	
	#zoom_icon_plus {
		color : #45a09f;
    }
	
	
}
