@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');
.egmMapPage {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
}

.custom__container {
    max-width: 90%;
    margin: 0 auto;
}


/* Header */

header {
    padding: 15px 0;
    font-family: 'Open Sans', sans-serif;
}

header nav ul li a {
    font-size: 22px;
    color: #000 !important;
    font-weight: 100;
}

header nav ul li {
    margin: 0 20px;
}

header nav ul li a i {
    color: #459F9F;
    margin-left: 10px;
}

header nav ul li a:after {
    position: relative;
    top: 5px;
}

header nav ul li .dropdown-menu a {
    font-size: 18px;
    font-weight: 100;
}

header nav ul li a.nav-link.active {
    font-weight: 600;
    color: #AE446D !important;
}


/* Banner */

.banner {
    background-image: url(../../../assets/img/banner.jpg);
    background-repeat: no-repeat;
    /* background-size: 100% 100%; */
	background-size: cover;
    background-position: top;
    padding: 30px 0 25px 0;
    /* background-attachment: fixed; */
}

.tab-content h1,
.tab-content h2,
.tab-content h3,
.tab-content h4,
.tab-content h5,
.tab-content h6 {
    color: #153180 !important;
    text-shadow: none !important;
    font-weight: 600 !important;
    line-height: 27px !important;
    padding-top: 0px !important;
    margin-bottom: 10px !important;
    font-family: 'Open Sans', sans-serif !important;
}

.tab-content h1 {
    font-size: 18px !important;
}

.tab-content h2 {
    font-size: 17px !important;
}

.tab-content h3 {
    font-size: 16px !important;
}

.tab-content h4 {
    font-size: 15px !important;
}

.tab-content h5 {
    font-size: 14px !important;
}

.tab-content h6 {
    font-size: 13px !important;
}

.banner_content h1 {
    color: #fff;
    text-align: left;
    margin: 0 0 12px 0;
    font-family: 'Open Sans', sans-serif;
    font-weight: 800;
    line-height: 40px;
    font-size: 2.1rem;
	width: 80%;
}

@media screen and (max-width: 719px) {
   .banner_content h1 {
		width: 100%;
   }
}

.banner_content {
    max-width: 71%;
    margin: 0 auto;
}

.modification_date {
    display: flex;
}

.modification_date p {
    color: #fff;
    margin: 0;
    font-size: 13px;
    font-weight: 300;
    font-family: 'Open Sans', sans-serif;
}

.search_dropdown ul li .dropdown1 .css-26l3qy-menu .css-4ljt47-MenuList>div {
    font-size: 14px;
}

.modification_date p i {
    margin-right: 10px;
    color: #459F9F;
}

.modification_date p:last-child {
    margin-left: 25px;
}

.banner_content .banner_body p {
    color: #fff;
    font-family: 'Open Sans', sans-serif;
    margin: 0;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 300;
}

.banner_content .banner_body {
    margin-top: 10px;
}


/* Tab Layout */

.banner_tab .nav-tabs li a.active {
    border-radius: 0;
    background-color: #45A09F;
    border-color: #45A09F;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    border-bottom: 0;
    font-family: 'Open Sans', sans-serif;
    filter: drop-shadow(0px 0px 100px black);
}

.span_update_chart {
    filter: drop-shadow(0px 0px 100px black);
}

.banner_tab .nav-tabs li:first-child a {
    border-top-left-radius: 10px!important;
}

.banner_tab .tab-content {
    background-color: #fff;
    padding: 20px 20px;
    border-top: 5px solid #45A09F;
}

.banner_tab .tab-content>div p {
    margin: 0;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 400;
}

.banner_tab .nav-tabs li:hover a {
    border: none;
    color: #fff;
    background-color: #45A09F;
    border: 1px solid #45A09F;
    border-bottom: transparent;
}

.banner_tab .nav-tabs li a {
    background-color: #DEDEDE;
    color: #000;
    font-size: 15px;
    font-weight: 600;
    border-radius: 0;
    border-right: 1px solid #d5d2d2;
    border-bottom: 0;
    font-family: 'Open Sans', sans-serif;
}

.banner_tab ul {
    display: inline-table;
    border-bottom: 0;
}

.banner_tab ul li {
    display: table-cell;
}

.banner_tab .tab-pane ul {
    display: block!important;
}

.banner_tab .tab-pane ul li {
    display: list-item!important;
}

.banner .banner_tab {
    margin-top: 15px;
    position: relative;
}

.banner_description i {
    color: #fff;
    font-size: 80px;
    display: inline-block;
    vertical-align: middle;
    margin-top: 10px;
}

.banner_description span {
    color: #fff;
    font-size: 15px;
    line-height: normal;
    margin: 0;
    padding-left: 15px;
    display: inline-block;
    width: 90%;
    vertical-align: top;
    font-weight: 400;
}

.banner_description span p {
    display: inline-block;
    margin: 0;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 300;
    font-family: 'Open Sans', sans-serif;
}

.banner_description {
    margin-top: 17px;
}

.banner_description span a {
    display: inline-block;
    color: #fff;
    font-style: italic;
    text-decoration: underline;
    word-break: break-word;
}

.research_report {
    float: right;
}

@media (max-width: 1200px) {
    .research_report {
        position: absolute;
        bottom: -30px;
        right: 0;
    }
}

.research_report a {
    color: #fff;
    padding: 0 10px;
    text-decoration: underline;
    font-size: 13px;
    line-height: 1.5;
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
}


/* Map */

.map h3 {
    font-size: 30px;
    font-weight: 600;
    color: #5FACAB;
    margin: 0 0 20px 0;
}

.map {
    padding: 50px 0;
}

.map p {
    font-size: 20px;
    font-weight: lighter;
    margin: 0 0 25px 0;
}

.map p:last-child {
    margin: 0;
}

.map_section img {
    display: block;
    margin: 50px auto 50px;
}


/* Graph */

.graph_inner h3 {
    font-size: 30px;
    font-weight: 600;
    color: #5FACAB;
    margin: 0 0 20px 0;
}

.graph_inner p {
    font-size: 20px;
    font-weight: lighter;
    margin: 0 0 25px 0;
}

.graph_inner img {
    margin: 60px auto;
    display: block;
    box-shadow: 0 0 3px rgb(0 0 0 / .3);
    padding: 30px;
    width: 80%;
}

.graph_image>button {
    float: right;
    padding: 0px 11px;
    font-size: 30px;
    line-height: normal;
    border: 1px solid #000;
    cursor: pointer;
    color: #888;
}

.graph_section.graph2 {
    padding: 70px 0;
}

.graph_section.graph1 {
    padding: 60px 0;
}

.graph_section.graph2 p:last-child {
    margin: 0;
}


/* Search Dropdown */

.search_dropdown {
    margin-top: 25px;
}

.search_dropdown ul {
    display: flex;
    flex-grow: 1;
    list-style: none;
    flex-wrap: wrap;
    background-color: #707070;
    padding: 25px 0;
    margin: 0;
    align-items: flex-end;
	border-radius: 8px;					   
}

.search_dropdown ul {
    align-items: center;
    position: relative;
    max-width: 80%;
}

.search_dropdown ul li {
    flex: 0 0 25%;
    max-width: 25%;
    margin: 0 0 10px 0;
    padding: 0 12px;
}

.search_dropdown ul li label {
    margin: 0;
    display: block;
    color: #fff;
    font-size: 13px;
    line-height: 1.5;
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
}

.css-1hb7zxy-IndicatorsContainer {
    display: none;
}

.css-2613qy-menu {
    position: relative;
    left: -5px !important;
}

.css-1hb7zxy-IndicatorsContainer {
    align-items: flex-end;
    /* height: 40px; */
}

.css-g1d714-ValueContainer {
    max-height: 40px;
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.css-yk16xz-control {
    width: 100% !important;
}

.search_dropdown ul li select {
    width: 100%;
    min-height: 33px;
    padding: 0 9px;
    font-size: 18px;
    font-family: 'Open Sans', sans-serif;
    outline: none;
    margin-top: 5px;
    margin-bottom: 0;
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    background-image: url(data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20256%20448%22%20enable-background%3D%22new%200%200%20256%20448%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E.arrow%7Bfill%3A%23424242%3B%7D%3C%2Fstyle%3E%3Cpath%20class%3D%22arrow%22%20d%3D%22M255.9%20168c0-4.2-1.6-7.9-4.8-11.2-3.2-3.2-6.9-4.8-11.2-4.8H16c-4.2%200-7.9%201.6-11.2%204.8S0%20163.8%200%20168c0%204.4%201.6%208.2%204.8%2011.4l112%20112c3.1%203.1%206.8%204.6%2011.2%204.6%204.4%200%208.2-1.5%2011.4-4.6l112-112c3-3.2%204.5-7%204.5-11.4z%22%2F%3E%3C%2Fsvg%3E%0A);
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: auto 37%;
    padding-right: 20px;
    vertical-align: middle;
}

@-moz-document url-prefix() {
    .search_dropdown ul li select {
        padding-top: 6px;
    }
}

.search_dropdown ul li .dropdown1 {
    height: 41px;
    padding: 0 10px;
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
    outline: none;
    margin-top: 5px;
    margin-bottom: 0;
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    background-image: url(data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20256%20448%22%20enable-background%3D%22new%200%200%20256%20448%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E.arrow%7Bfill%3A%23424242%3B%7D%3C%2Fstyle%3E%3Cpath%20class%3D%22arrow%22%20d%3D%22M255.9%20168c0-4.2-1.6-7.9-4.8-11.2-3.2-3.2-6.9-4.8-11.2-4.8H16c-4.2%200-7.9%201.6-11.2%204.8S0%20163.8%200%20168c0%204.4%201.6%208.2%204.8%2011.4l112%20112c3.1%203.1%206.8%204.6%2011.2%204.6%204.4%200%208.2-1.5%2011.4-4.6l112-112c3-3.2%204.5-7%204.5-11.4z%22%2F%3E%3C%2Fsvg%3E%0A);
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: auto 37%;
	border-radius: 5px;
    width: calc(100% - 70px);
    flex:1;
}

.filter_condition_row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.filter_condition {
    width: 31% !important;
    margin-left: 2%;
    max-height: 40px;
    max-width: 61px !important;
}

.filter_common_condition.filter_condition_row label {
    width: 70%;
}

.filter_common_list {
    width: 7% !important;
    margin-left: 2%;
    padding-top: 0 !important;
}

.filter_without_condition {
    width: 100% !important;
}

#download {
    color: #000;
}

.css-1pahdxg-control,
.css-1pahdxg-control:hover {
    border: none!important;
    outline: none !important;
    border-color: #fff!important;
    box-shadow: none !important;
}

.css-yk16xz-control {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: hsl(0, 0%, 100%);
    border-color: #ffffff;
    /* border-radius: 4px; */
    border-style: solid;
    border-width: 0px!important;
    cursor: default;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    min-height: unset !important;
    outline: 0!important;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    box-sizing: border-box;
    outline: none !important;
    margin-top: 1px;
}

.css-1pahdxg-control {	
	min-height: unset !important;
	outline: none !important;
	border-width: 0px!important;
	box-sizing: none!important;
}

.search_dropdown ul li .dropdown1 {
    padding: 0 7px!important;
}

.css-1rhbuit-multiValue {
    margin-left: -2px!important;
}							  
.search_dropdown ul button {
    margin: 0 50px;
    padding: 0 36px;
    border: none;
    max-height: 50px;
    height: 40px;
    margin-bottom: 0;
    background-color: #45A09F;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}

.box_icon {
    border-radius: 50%;
}

.css-1gtu0rj-indicatorContainer {
    background-image: url(data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20256%20448%22%20enable-background%3D%22new%200%200%20256%20448%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E.arrow%7Bfill%3A%23424242%3B%7D%3C%2Fstyle%3E%3Cpath%20class%3D%22arrow%22%20d%3D%22M255.9%20168c0-4.2-1.6-7.9-4.8-11.2-3.2-3.2-6.9-4.8-11.2-4.8H16c-4.2%200-7.9%201.6-11.2%204.8S0%20163.8%200%20168c0%204.4%201.6%208.2%204.8%2011.4l112%20112c3.1%203.1%206.8%204.6%2011.2%204.6%204.4%200%208.2-1.5%2011.4-4.6l112-112c3-3.2%204.5-7%204.5-11.4z%22%2F%3E%3C%2Fsvg%3E%0A);
    background-position: right;
    background-repeat: no-repeat;
    background-size: auto 70%;
    width: 20px;
    height: 20px;
}

.css-1gtu0rj-indicatorContainer svg {
    display: none;
}

.search_dropdown ul button i {
    margin-right: 10px;
    font-size: 26px;
    vertical-align: middle;
}

.search_result>button {
    padding: 0px 11px;
    font-size: 30px;
    line-height: normal;
    border: 1px solid #000;
    cursor: pointer;
    display: block;
    margin-left: calc(100% - 13%);
    margin-bottom: 40px;
    color: #888;
}

.search_result {
    padding: 40px 0;		 
}

.search_result>img {
    width: 80%;
    margin: 0 auto;
    display: block;
}

.search_result>ul {
    background-color: #fff;
    width: 80%;
    margin: 0 auto;
    justify-content: space-between;
    align-items: baseline;
}

.search_result>ul li {
    margin: 0;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
}

.search_result>ul li:first-child:before {
    background-color: #D7D7D7;
    width: 10px;
    height: 10px;
    display: inline-block;
    content: '';
    vertical-align: middle;
    border-radius: 50%;
    margin-right: 10px;
}

.search_result>ul li:nth-child(2):before {
    background-color: #C8D87F;
    width: 10px;
    height: 10px;
    display: inline-block;
    content: '';
    vertical-align: middle;
    border-radius: 50%;
    margin-right: 10px;
}

.search_result>ul li:nth-child(3):before {
    background-color: #EAB490;
    width: 10px;
    height: 10px;
    display: inline-block;
    content: '';
    vertical-align: middle;
    border-radius: 50%;
    margin-right: 10px;
}

.search_result>ul li:nth-child(4):before {
    background-color: #E39B9C;
    width: 10px;
    height: 10px;
    display: inline-block;
    content: '';
    vertical-align: middle;
    border-radius: 50%;
    margin-right: 10px;
}

.search_result>ul li:nth-child(5):before {
    background-color: #8F9ABC;
    width: 10px;
    height: 10px;
    display: inline-block;
    content: '';
    vertical-align: middle;
    border-radius: 50%;
    margin-right: 10px;
}

.search_result>ul li:last-child:before {
    background-color: #D0FAF5;
    width: 10px;
    height: 10px;
    display: inline-block;
    content: '';
    vertical-align: middle;
    margin-right: 10px;
    border: 1px solid #000;
}


/* Footer */

footer {
    background-color: #21201E;
}

footer p {
    color: #fff;
    text-align: center;
    margin: 0;
    padding: 25px 0;
    font-size: 17px;
}


/*popup fix*/

svg#main_map {
    width: 100% !important;
    font-family: 'Open Sans', sans-serif;
}

.modal {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    z-index: 1050;
    display: none;
    overflow: visible !important;
    outline: 0;
    transform: translate(-50%, -50%) !important;
}

.modalDefinition {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    z-index: 1050;
    display: none;
    overflow: visible !important;
    outline: 0;
    transform: translate(-50%, -50%) !important;
}

.modalNotification {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    z-index: 1050;
    display: none;
    overflow: visible !important;
    outline: 0;
    transform: translate(-50%, -50%) !important;
}					
circle:focus {
    outline: none;
    stroke: #000 !important;
}

.search_top_filter select:focus, .filter_common_list:focus {
    border: 2px solid #000 !important;
}

.dropdown1:focus-within {
    /* border: 1px solid #000 !important; */
    box-shadow: 0 0 0 2px #000;
}

.side_filter_btn:focus {
    border: 2px solid #707070 !important;
}

/* Social Section */

.social_icons {
    position: fixed;
    top: 190px;
    background-color: white;
    padding: 10px 8px 0px 5px;
    z-index: 55;
    margin-left: -43px;
    border-radius: 6px;
}

.social_icons_embedded {
    top: 20px !important;
}

.social_icons ul {
    list-style: none;
    text-align: center;
    margin-bottom: 7px;
    margin-top: -1px;
	margin-left: 5px;				 
}

.social_icons ul li a {
    font-size: 20px;
}

.hidden_image {
    position: absolute;
}


.hidden_image.side_filter {
    padding: 7px 21px;
    left: 0;
}

.hidden_image.popup_options {
    padding: 14px 11px;
    left: 0;
    top: 0;
}

.hidden_image.popup_options {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

.social-share-email{
   margin-top: -3px!important;
}

.social-share-twitter{
    margin-top: -3px!important;
}

.social-share-facebook{
  margin-bottom: -2px!important;
}

.social-share-linkedin{
	margin-bottom: -4px!important;
}

.social-share-email  a {
   background-color: #a1331a;
    color: #fff;
    padding: 0 3px 0px;
    border-radius: 5px;
}


.social-share-twitter  a {
    color: #4dc1e0;
}

.social-share-facebook  a {
    color: #1781d3;
}


.social-share-email  a i{
    line-height: 22px;
    margin-top: -10px;
	font-size: 23px;
}

.social-share-twitter  a i {
    line-height: 27px;
    font-size: 33px;
}

 
.social-share-facebook  a i {
    line-height: 28px;
    margin-top: 0px;
    font-size: 33px;
}

.social-share-linkedin  a i {
    line-height: 22px;
    margin-top: 7px;
    font-size: 33px;
}


						

.social_icons ul li {
    margin-bottom: 8px;
}					 
.social_icons ul li:last-child a {
    color: #1781D3;
}




/* About Page */

.about {
    padding: 70px 0;
}

.about .about_inner h3 {
    font-size: 34px;
    font-weight: 600;
    color: #45a09f;
    margin: 0 0 20px 0;
}

.about .about_inner p {
    font-size: 20px;
    font-weight: lighter;
}

.about .about_inner p:last-child {
    margin: 0;
}

.footer_content {
    padding-top: 2px;
}

.research_content iframe{
    border: none;
}

.footer_content h1,
.footer_content h2,
.footer_content h3,
.footer_content h4,
.footer_content h5,
.footer_content h6 {
    color: #153180;
    margin-top: 10px;
    font-weight: 600;
    line-height: 27px;
    padding-top: 0px;
    margin-bottom: 10px;
    font-family: 'Open Sans', sans-serif;
}

.footer_content h1 {
    font-size: 18px;
}

.footer_content h2 {
    font-size: 17px;
}

.footer_content h3 {
    font-size: 16px;
}

.footer_content h4 {
    font-size: 15px;
}

.footer_content h5 {
    font-size: 14px;
}

.footer_content h6 {
    font-size: 13px;
}

.footer_content p {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 15px;
    font-family: 'Open Sans', sans-serif;
}

.side_filter_btn {
    cursor: pointer;
}

.search_result {
    height: 800px;
}

@-moz-document url-prefix() {
	.search_result {
		height: 1340px;
	}
}							 
.banner_description svg {
    margin-top: 8px;
}

.css-1gtu0rj-indicatorContainer {
    display: none !important;
}

div.needHeight {
    height: 21px;
}

.egmMapPage .banner_body a {
    color: #60a2e9;
    text-decoration: none!important;
    font-style: normal!important;
}

.egmMapPage .banner_description a {
    color: #60a2e9;
    text-decoration: none!important;
    font-style: normal!important;
}

.egmMapPage .footer_content a {
    color: #000;
    text-decoration: none!important;
    font-style: normal!important;
}

.egmMapPage .tab-pane a {
    color: #418bdb;
    text-decoration: none!important;
    font-style: normal!important;
}

.egmMapPage .footer_content p a {
    color: #418bdb;
}

.egmMapPage .research_report a {
    text-decoration: none!important;
}



/* Responsive */
@media (min-width: 1300px) {
    /* .filter_common_condition.filter_condition_row label, .search_dropdown ul li .dropdown1 {
        width: 67%;
    } */
}
@media (min-width: 1600px) {
    /* .filter_common_condition.filter_condition_row label, .search_dropdown ul li .dropdown1 {
        width: 75%;
    } */

    .filter_condition {
        width: 17% !important;
        min-width: 65px !important;
        max-width: 65px !important;
    }
}

@media (max-width: 1852px) {
    .search_dropdown ul li {
        flex: 0 0 25%;
        max-width: 25%;
        margin: 0 0 10px 0;
        padding: 0 12px;
    }
}
@media (min-width: 1852px) {
    .search_dropdown ul li {
        flex: 0 0 20%;
        max-width: 20%;
        margin: 0 0 10px 0;
        padding: 0 12px;
    }
    /* .filter_common_condition.filter_condition_row label, .search_dropdown ul li .dropdown1 {
        width: 73%;
    } */
}
@media (min-width: 2020px) {
    /* .filter_common_condition.filter_condition_row label, .search_dropdown ul li .dropdown1 {
        width: 85%;
    } */
}
@media (min-width: 2020px) and (max-width: 2760px) {
    /* .filter_common_condition.filter_condition_row label, .search_dropdown ul li .dropdown1 {
        width: 74%;
    } */
    .css-12jo7m5{
        flex: 0 0 90% !important;
    }
}
@media (min-width: 767px) and (max-width:1200px) {
    .search_dropdown ul li {
        flex: 0 0 33% !important;
        max-width: 33% !important;
        margin: 0 0 10px 0;
        padding: 0 12px;
    }
}
@media (max-width: 1200px) {
    .search_dropdown ul {
        align-items: flex-end;
        max-width: 100% !important;
    }
    .css-1wa3eu0-placeholder,
    .search_dropdown ul li select {
        font-size: 14px;
    }
}

@media (max-width: 1200px) {
    .search_dropdown ul li {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .filter_common_list {
        width: 13% !important;
    }

    .uc_container {
        max-width: 100% !important;
        width: 100% !important;
        margin: 10px 0px 4px 0px !important;
    }
    .filter_common_condition.filter_condition_row label, .search_dropdown ul li .dropdown1{
        width: calc(100% - 70px);
        max-width: inital;
    }
}

@media (max-width: 767px) {
    .search_dropdown ul li {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .filter_common_list {
        width: 50% !important;
        margin-left: 0;
    }
    /* .filter_common_condition.filter_condition_row label, .search_dropdown ul li .dropdown1{
        width: 74% !important;
        max-width: inital;
    } */
}

@media (max-width: 620px) {
    .search_dropdown ul li {
        flex: 0 0 100%;
        max-width: 100%;
    }
    
    .filter_common_list {
        width: 40% !important;
    }
}

@media only screen and (max-width: 1300px) {
    .banner_content h1 {
        font-size: 38px;
        line-height: 40px;
    }
    .modification_date p {
        color: #fff;
        margin: 0;
    }
    .search_dropdown ul button {
        margin: 0 10px;
        padding: 0px 18px;
        margin-bottom: 0;
    }
    .search_result>ul li {
        margin: 0;
        text-align: left;
        max-width: 33% !important;
        flex: 0 0 33% !important;
        margin-bottom: 5px;
    }

    /* .filter_common_condition.filter_condition_row label, .search_dropdown ul li .dropdown1 {
        width: 67%; */
        /* max-width: 67%;
    } */
    /* .filter_common_condition.filter_condition_row label, .search_dropdown ul li .dropdown1.filter_without_condition {
        max-width: 100% !important;
        width:100% !important;
    } */

    .filter_condition {
        width: 23% !important;
        min-width: 65px !important;
        max-width: 65px !important;
    }
}
@media only screen and (max-width: 1200px) {
    .filter_condition {
        width: 22% !important;
        min-width: 65px !important;
        max-width: 65px !important;
    }
    .filter_common_condition{
        display: flex !important;
	    padding-right: 20px;
		/* padding-right: 20px; Need to confirm */
    }
    .fc_label_container{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: .6rem .5rem;
        margin-left: 16px;
    }
    .fc_label_container label{
        font-size: 1rem;
    }
    .uc_container select{
        width: 100% !important;
        min-height: 34px !important;
    }
    .uc_container{
        padding: 16px 0px 18px 0px !important;
    }
    .needHeight{
        height: unset !important;
    }    
   
}
@media only screen and (max-width: 767px) {
    .css-1rhbuit-multiValue{
        margin: 1px !important;
    }
    .css-g1d714-ValueContainer{
        padding: unset !important;
        /* max-height: 29px !important; */
    }

}
.css-1rhbuit-multiValue{
    margin: 1px !important;
    /* max-width: calc(100% - 5px); */
    max-width: 93%;
    /* flex-wrap: wrap; */
}
.css-g1d714-ValueContainer{
    padding: unset !important; 
    /* max-height: 31px !important; */
}

.css-1wa3eu0-placeholder{
    top: 50% !important;
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
    .filter_condition {
        width: 22% !important;
        max-width: 65px !important;
        min-width: 65px !important;
    }
}
@media only screen and (max-width: 1200px) {
    .map_section img {
        width: 100%;
    }
    .search_dropdown ul button {
        margin: 5px 10px 0;
        padding: 0px 14px;
        margin-left: 50px;
    }
    header nav ul li a {
        font-size: 16px;
    }
    header nav ul li .dropdown-menu a {
        font-size: 16px;
    }
    .banner_tab .nav-tabs li a,
    .banner_tab .nav-tabs li a.active {
        font-size: 18px;
    }
    .banner_description {
        margin-top: 50px;
        display: flex;
    }
}

@media only screen and (max-width: 992px) {
    .modification_date {
        display: flex;
        flex-wrap: wrap;
    }
    .modification_date p:last-child {
        margin-left: 0;
    }
    .search_dropdown ul button {
        padding: 0px 37px;
    }
    .banner_tab .nav-tabs li a,
    .banner_tab .nav-tabs li a.active {
        font-size: 14px;
    }
    .research_report {
        display: block;
        float: none;
        text-align: right;
    }
    .search_dropdown ul button {
        left: 50%;
    }    
}

@media only screen and (max-width: 767px) {
    .banner {
        padding: 50px 0;
    }
    .search_dropdown ul button {
        padding: 0px 10px;
        display: block;
        width: 100%;
        margin: 0 auto 0;
        font-size: 16px;
    }
    .search_result>ul li {
        max-width: 50% !important;
        flex: 0 0 50% !important;
        margin-bottom: 5px;
        padding-right: 10px;
    }
    .banner_tab ul li a {
        min-height: 59px;
    }
}

@media screen and (max-width: 575px) {
    header .navbar a {
        width: 64%;
    }
    header .navbar a img {
        width: 100%;
    }
    .banner_content h1 {
        font-size: 26px;
        line-height: 26px;
    }
    .social_icons {
        padding: 8px 6px 0px 3px;
        background-color: white;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }
    .search_dropdown ul li {
        flex: 0 0 100%;
        max-width: 100%;
        margin: 0;
        padding: 0 12px;
    }
    .search_dropdown ul button {
        margin: 0 auto;
        position: static;
        max-width: 100% !important;
    }
    .search_result>button {
        margin-left: auto;
    }
    .search_result>ul li {
        max-width: 100% !important;
        flex: 0 0 100% !important;
        margin-bottom: 5px !important;
        padding-right: 0;
    }
    .search_dropdown ul {
        padding-bottom: 0;
        align-items: center;
    }
    footer p {
        color: #fff;
        text-align: center;
        margin: 0;
        padding: 25px 10px;
        font-size: 14px;
    }
	.banner_tab .nav-tabs li:first-child a {
        border-top-left-radius: 10px!important;
        border-top-right-radius: 10px!important;
    }					   
    .banner_tab ul {
        display: flex;
        border-bottom: 0;
        /*overflow: hidden;
        overflow-x: scroll;*/
        flex-wrap: wrap;
    }
    .banner_tab ul li {
        flex: 0 0 100% !important;
        max-width: 100% !important;
		border-bottom: 2px solid #45A09F;				
    }
    .about .about_inner p {
        font-size: 18px;
        font-weight: lighter;
    }
    .fc_label_container label {
        font-size: .9rem !important;       
    }
    .fc_label_container {
        padding-left: unset !important;
    }
    .uc_container select{
        width: unset !important;
    }
    .f_vertical ul {
        padding-bottom: 1rem !important;
    }
}

.uc_btn {
    background-color: rgb(69, 160, 159);
    visibility: visible;
    font-size: 15px;
    font-weight: 600;
    border: none;
    color: #fff;
    border-radius: 4px;
    padding: 8px 18px;
}

.uc_container {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    /* margin: 20px 20px 20px 2px; */
    background-color: #dfdfdf;
    border-radius: 8px;
    max-width: 19%;
    text-align: center;
    justify-content: center;
    margin-left: 1%;
}

.uc_container select {
    /* width: 50%; */
    min-height: 23px;
    padding: 5px 0px 5px 8px;
    font-size: 14px;
    border-radius: 4px;
    font-family: 'Open Sans', sans-serif;
    /* outline: none; */
    margin-top: 5px;
    margin-bottom: 0;
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    background-image: url(data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20256%20448%22%20enable-background%3D%22new%200%200%20256%20448%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E.arrow%7Bfill%3A%23424242%3B%7D%3C%2Fstyle%3E%3Cpath%20class%3D%22arrow%22%20d%3D%22M255.9%20168c0-4.2-1.6-7.9-4.8-11.2-3.2-3.2-6.9-4.8-11.2-4.8H16c-4.2%200-7.9%201.6-11.2%204.8S0%20163.8%200%20168c0%204.4%201.6%208.2%204.8%2011.4l112%20112c3.1%203.1%206.8%204.6%2011.2%204.6%204.4%200%208.2-1.5%2011.4-4.6l112-112c3-3.2%204.5-7%204.5-11.4z%22%2F%3E%3C%2Fsvg%3E%0A);
    background-position: right 8px center;
    background-repeat: no-repeat;
    background-size: auto 37%;
    padding-right: 20px;
    vertical-align: middle;
    border: none;
    max-width: 68px;
}
@media (min-width: 3840px) {
    /* .filter_common_condition.filter_condition_row label, .search_dropdown ul li .dropdown1{
        width: 85% !important;
    } */
}
.css-1hwfws3 {
    padding :2px 2px !important;
}
.f_vertical{
    flex-direction: column !important;
}
.f_vertical ul{
    max-width: 100% !important;
    width: 100%;
    /* padding-top: .09rem !important;
    padding-bottom: unset !important; */
}
@media (min-width: 640px) {
    .f_vertical .filter_common_condition {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .f_vertical .uc_container {
        max-width: 100% !important;
        margin-left: unset !important;
        flex-direction: row;
        justify-content: center;
        margin-top: .5rem;
    }
    .f_vertical .uc_container select {
        /* margin-top: unset !important; */
        margin-left: 5px;
    }
    .f_vertical .uc_container .needHeight {
        height: unset !important;
    }
    .f_vertical .uc_btn {
        margin-left: 10px;
    }
    .f_vertical .fc_label_container label {
        margin-bottom: unset !important;
    }
    .f_vertical .uc_container .fc_select_wrap {
        align-self: unset !important;
    }
	
	
	
	
}






	
