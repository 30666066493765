@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap);
.egmMapPage {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
}

.custom__container {
    max-width: 90%;
    margin: 0 auto;
}


/* Header */

header {
    padding: 15px 0;
    font-family: 'Open Sans', sans-serif;
}

header nav ul li a {
    font-size: 22px;
    color: #000 !important;
    font-weight: 100;
}

header nav ul li {
    margin: 0 20px;
}

header nav ul li a i {
    color: #459F9F;
    margin-left: 10px;
}

header nav ul li a:after {
    position: relative;
    top: 5px;
}

header nav ul li .dropdown-menu a {
    font-size: 18px;
    font-weight: 100;
}

header nav ul li a.nav-link.active {
    font-weight: 600;
    color: #AE446D !important;
}


/* Banner */

.banner {
    background-image: url(/static/media/banner.78be5f8b.jpg);
    background-repeat: no-repeat;
    /* background-size: 100% 100%; */
	background-size: cover;
    background-position: top;
    padding: 30px 0 25px 0;
    /* background-attachment: fixed; */
}

.tab-content h1,
.tab-content h2,
.tab-content h3,
.tab-content h4,
.tab-content h5,
.tab-content h6 {
    color: #153180 !important;
    text-shadow: none !important;
    font-weight: 600 !important;
    line-height: 27px !important;
    padding-top: 0px !important;
    margin-bottom: 10px !important;
    font-family: 'Open Sans', sans-serif !important;
}

.tab-content h1 {
    font-size: 18px !important;
}

.tab-content h2 {
    font-size: 17px !important;
}

.tab-content h3 {
    font-size: 16px !important;
}

.tab-content h4 {
    font-size: 15px !important;
}

.tab-content h5 {
    font-size: 14px !important;
}

.tab-content h6 {
    font-size: 13px !important;
}

.banner_content h1 {
    color: #fff;
    text-align: left;
    margin: 0 0 12px 0;
    font-family: 'Open Sans', sans-serif;
    font-weight: 800;
    line-height: 40px;
    font-size: 2.1rem;
	width: 80%;
}

@media screen and (max-width: 719px) {
   .banner_content h1 {
		width: 100%;
   }
}

.banner_content {
    max-width: 71%;
    margin: 0 auto;
}

.modification_date {
    display: flex;
}

.modification_date p {
    color: #fff;
    margin: 0;
    font-size: 13px;
    font-weight: 300;
    font-family: 'Open Sans', sans-serif;
}

.search_dropdown ul li .dropdown1 .css-26l3qy-menu .css-4ljt47-MenuList>div {
    font-size: 14px;
}

.modification_date p i {
    margin-right: 10px;
    color: #459F9F;
}

.modification_date p:last-child {
    margin-left: 25px;
}

.banner_content .banner_body p {
    color: #fff;
    font-family: 'Open Sans', sans-serif;
    margin: 0;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 300;
}

.banner_content .banner_body {
    margin-top: 10px;
}


/* Tab Layout */

.banner_tab .nav-tabs li a.active {
    border-radius: 0;
    background-color: #45A09F;
    border-color: #45A09F;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    border-bottom: 0;
    font-family: 'Open Sans', sans-serif;
    -webkit-filter: drop-shadow(0px 0px 100px black);
            filter: drop-shadow(0px 0px 100px black);
}

.span_update_chart {
    -webkit-filter: drop-shadow(0px 0px 100px black);
            filter: drop-shadow(0px 0px 100px black);
}

.banner_tab .nav-tabs li:first-child a {
    border-top-left-radius: 10px!important;
}

.banner_tab .tab-content {
    background-color: #fff;
    padding: 20px 20px;
    border-top: 5px solid #45A09F;
}

.banner_tab .tab-content>div p {
    margin: 0;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 400;
}

.banner_tab .nav-tabs li:hover a {
    border: none;
    color: #fff;
    background-color: #45A09F;
    border: 1px solid #45A09F;
    border-bottom: transparent;
}

.banner_tab .nav-tabs li a {
    background-color: #DEDEDE;
    color: #000;
    font-size: 15px;
    font-weight: 600;
    border-radius: 0;
    border-right: 1px solid #d5d2d2;
    border-bottom: 0;
    font-family: 'Open Sans', sans-serif;
}

.banner_tab ul {
    display: inline-table;
    border-bottom: 0;
}

.banner_tab ul li {
    display: table-cell;
}

.banner_tab .tab-pane ul {
    display: block!important;
}

.banner_tab .tab-pane ul li {
    display: list-item!important;
}

.banner .banner_tab {
    margin-top: 15px;
    position: relative;
}

.banner_description i {
    color: #fff;
    font-size: 80px;
    display: inline-block;
    vertical-align: middle;
    margin-top: 10px;
}

.banner_description span {
    color: #fff;
    font-size: 15px;
    line-height: normal;
    margin: 0;
    padding-left: 15px;
    display: inline-block;
    width: 90%;
    vertical-align: top;
    font-weight: 400;
}

.banner_description span p {
    display: inline-block;
    margin: 0;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 300;
    font-family: 'Open Sans', sans-serif;
}

.banner_description {
    margin-top: 17px;
}

.banner_description span a {
    display: inline-block;
    color: #fff;
    font-style: italic;
    text-decoration: underline;
    word-break: break-word;
}

.research_report {
    float: right;
}

@media (max-width: 1200px) {
    .research_report {
        position: absolute;
        bottom: -30px;
        right: 0;
    }
}

.research_report a {
    color: #fff;
    padding: 0 10px;
    text-decoration: underline;
    font-size: 13px;
    line-height: 1.5;
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
}


/* Map */

.map h3 {
    font-size: 30px;
    font-weight: 600;
    color: #5FACAB;
    margin: 0 0 20px 0;
}

.map {
    padding: 50px 0;
}

.map p {
    font-size: 20px;
    font-weight: lighter;
    margin: 0 0 25px 0;
}

.map p:last-child {
    margin: 0;
}

.map_section img {
    display: block;
    margin: 50px auto 50px;
}


/* Graph */

.graph_inner h3 {
    font-size: 30px;
    font-weight: 600;
    color: #5FACAB;
    margin: 0 0 20px 0;
}

.graph_inner p {
    font-size: 20px;
    font-weight: lighter;
    margin: 0 0 25px 0;
}

.graph_inner img {
    margin: 60px auto;
    display: block;
    box-shadow: 0 0 3px rgb(0 0 0 / .3);
    padding: 30px;
    width: 80%;
}

.graph_image>button {
    float: right;
    padding: 0px 11px;
    font-size: 30px;
    line-height: normal;
    border: 1px solid #000;
    cursor: pointer;
    color: #888;
}

.graph_section.graph2 {
    padding: 70px 0;
}

.graph_section.graph1 {
    padding: 60px 0;
}

.graph_section.graph2 p:last-child {
    margin: 0;
}


/* Search Dropdown */

.search_dropdown {
    margin-top: 25px;
}

.search_dropdown ul {
    display: flex;
    flex-grow: 1;
    list-style: none;
    flex-wrap: wrap;
    background-color: #707070;
    padding: 25px 0;
    margin: 0;
    align-items: flex-end;
	border-radius: 8px;					   
}

.search_dropdown ul {
    align-items: center;
    position: relative;
    max-width: 80%;
}

.search_dropdown ul li {
    flex: 0 0 25%;
    max-width: 25%;
    margin: 0 0 10px 0;
    padding: 0 12px;
}

.search_dropdown ul li label {
    margin: 0;
    display: block;
    color: #fff;
    font-size: 13px;
    line-height: 1.5;
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
}

.css-1hb7zxy-IndicatorsContainer {
    display: none;
}

.css-2613qy-menu {
    position: relative;
    left: -5px !important;
}

.css-1hb7zxy-IndicatorsContainer {
    align-items: flex-end;
    /* height: 40px; */
}

.css-g1d714-ValueContainer {
    max-height: 40px;
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.css-yk16xz-control {
    width: 100% !important;
}

.search_dropdown ul li select {
    width: 100%;
    min-height: 33px;
    padding: 0 9px;
    font-size: 18px;
    font-family: 'Open Sans', sans-serif;
    outline: none;
    margin-top: 5px;
    margin-bottom: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
         appearance: none;
    background-color: #fff;
    background-image: url(data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20256%20448%22%20enable-background%3D%22new%200%200%20256%20448%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E.arrow%7Bfill%3A%23424242%3B%7D%3C%2Fstyle%3E%3Cpath%20class%3D%22arrow%22%20d%3D%22M255.9%20168c0-4.2-1.6-7.9-4.8-11.2-3.2-3.2-6.9-4.8-11.2-4.8H16c-4.2%200-7.9%201.6-11.2%204.8S0%20163.8%200%20168c0%204.4%201.6%208.2%204.8%2011.4l112%20112c3.1%203.1%206.8%204.6%2011.2%204.6%204.4%200%208.2-1.5%2011.4-4.6l112-112c3-3.2%204.5-7%204.5-11.4z%22%2F%3E%3C%2Fsvg%3E%0A);
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: auto 37%;
    padding-right: 20px;
    vertical-align: middle;
}

@-moz-document url-prefix() {
    .search_dropdown ul li select {
        padding-top: 6px;
    }
}

.search_dropdown ul li .dropdown1 {
    height: 41px;
    padding: 0 10px;
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
    outline: none;
    margin-top: 5px;
    margin-bottom: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
         appearance: none;
    background-color: #fff;
    background-image: url(data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20256%20448%22%20enable-background%3D%22new%200%200%20256%20448%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E.arrow%7Bfill%3A%23424242%3B%7D%3C%2Fstyle%3E%3Cpath%20class%3D%22arrow%22%20d%3D%22M255.9%20168c0-4.2-1.6-7.9-4.8-11.2-3.2-3.2-6.9-4.8-11.2-4.8H16c-4.2%200-7.9%201.6-11.2%204.8S0%20163.8%200%20168c0%204.4%201.6%208.2%204.8%2011.4l112%20112c3.1%203.1%206.8%204.6%2011.2%204.6%204.4%200%208.2-1.5%2011.4-4.6l112-112c3-3.2%204.5-7%204.5-11.4z%22%2F%3E%3C%2Fsvg%3E%0A);
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: auto 37%;
	border-radius: 5px;
    width: calc(100% - 70px);
    flex:1 1;
}

.filter_condition_row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.filter_condition {
    width: 31% !important;
    margin-left: 2%;
    max-height: 40px;
    max-width: 61px !important;
}

.filter_common_condition.filter_condition_row label {
    width: 70%;
}

.filter_common_list {
    width: 7% !important;
    margin-left: 2%;
    padding-top: 0 !important;
}

.filter_without_condition {
    width: 100% !important;
}

#download {
    color: #000;
}

.css-1pahdxg-control,
.css-1pahdxg-control:hover {
    border: none!important;
    outline: none !important;
    border-color: #fff!important;
    box-shadow: none !important;
}

.css-yk16xz-control {
    align-items: center;
    background-color: hsl(0, 0%, 100%);
    border-color: #ffffff;
    /* border-radius: 4px; */
    border-style: solid;
    border-width: 0px!important;
    cursor: default;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: unset !important;
    outline: 0!important;
    position: relative;
    transition: all 100ms;
    box-sizing: border-box;
    outline: none !important;
    margin-top: 1px;
}

.css-1pahdxg-control {	
	min-height: unset !important;
	outline: none !important;
	border-width: 0px!important;
	box-sizing: none!important;
}

.search_dropdown ul li .dropdown1 {
    padding: 0 7px!important;
}

.css-1rhbuit-multiValue {
    margin-left: -2px!important;
}							  
.search_dropdown ul button {
    margin: 0 50px;
    padding: 0 36px;
    border: none;
    max-height: 50px;
    height: 40px;
    margin-bottom: 0;
    background-color: #45A09F;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}

.box_icon {
    border-radius: 50%;
}

.css-1gtu0rj-indicatorContainer {
    background-image: url(data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20256%20448%22%20enable-background%3D%22new%200%200%20256%20448%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E.arrow%7Bfill%3A%23424242%3B%7D%3C%2Fstyle%3E%3Cpath%20class%3D%22arrow%22%20d%3D%22M255.9%20168c0-4.2-1.6-7.9-4.8-11.2-3.2-3.2-6.9-4.8-11.2-4.8H16c-4.2%200-7.9%201.6-11.2%204.8S0%20163.8%200%20168c0%204.4%201.6%208.2%204.8%2011.4l112%20112c3.1%203.1%206.8%204.6%2011.2%204.6%204.4%200%208.2-1.5%2011.4-4.6l112-112c3-3.2%204.5-7%204.5-11.4z%22%2F%3E%3C%2Fsvg%3E%0A);
    background-position: right;
    background-repeat: no-repeat;
    background-size: auto 70%;
    width: 20px;
    height: 20px;
}

.css-1gtu0rj-indicatorContainer svg {
    display: none;
}

.search_dropdown ul button i {
    margin-right: 10px;
    font-size: 26px;
    vertical-align: middle;
}

.search_result>button {
    padding: 0px 11px;
    font-size: 30px;
    line-height: normal;
    border: 1px solid #000;
    cursor: pointer;
    display: block;
    margin-left: calc(100% - 13%);
    margin-bottom: 40px;
    color: #888;
}

.search_result {
    padding: 40px 0;		 
}

.search_result>img {
    width: 80%;
    margin: 0 auto;
    display: block;
}

.search_result>ul {
    background-color: #fff;
    width: 80%;
    margin: 0 auto;
    justify-content: space-between;
    align-items: baseline;
}

.search_result>ul li {
    margin: 0;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
}

.search_result>ul li:first-child:before {
    background-color: #D7D7D7;
    width: 10px;
    height: 10px;
    display: inline-block;
    content: '';
    vertical-align: middle;
    border-radius: 50%;
    margin-right: 10px;
}

.search_result>ul li:nth-child(2):before {
    background-color: #C8D87F;
    width: 10px;
    height: 10px;
    display: inline-block;
    content: '';
    vertical-align: middle;
    border-radius: 50%;
    margin-right: 10px;
}

.search_result>ul li:nth-child(3):before {
    background-color: #EAB490;
    width: 10px;
    height: 10px;
    display: inline-block;
    content: '';
    vertical-align: middle;
    border-radius: 50%;
    margin-right: 10px;
}

.search_result>ul li:nth-child(4):before {
    background-color: #E39B9C;
    width: 10px;
    height: 10px;
    display: inline-block;
    content: '';
    vertical-align: middle;
    border-radius: 50%;
    margin-right: 10px;
}

.search_result>ul li:nth-child(5):before {
    background-color: #8F9ABC;
    width: 10px;
    height: 10px;
    display: inline-block;
    content: '';
    vertical-align: middle;
    border-radius: 50%;
    margin-right: 10px;
}

.search_result>ul li:last-child:before {
    background-color: #D0FAF5;
    width: 10px;
    height: 10px;
    display: inline-block;
    content: '';
    vertical-align: middle;
    margin-right: 10px;
    border: 1px solid #000;
}


/* Footer */

footer {
    background-color: #21201E;
}

footer p {
    color: #fff;
    text-align: center;
    margin: 0;
    padding: 25px 0;
    font-size: 17px;
}


/*popup fix*/

svg#main_map {
    width: 100% !important;
    font-family: 'Open Sans', sans-serif;
}

.modal {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    z-index: 1050;
    display: none;
    overflow: visible !important;
    outline: 0;
    transform: translate(-50%, -50%) !important;
}

.modalDefinition {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    z-index: 1050;
    display: none;
    overflow: visible !important;
    outline: 0;
    transform: translate(-50%, -50%) !important;
}

.modalNotification {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    z-index: 1050;
    display: none;
    overflow: visible !important;
    outline: 0;
    transform: translate(-50%, -50%) !important;
}					
circle:focus {
    outline: none;
    stroke: #000 !important;
}

.search_top_filter select:focus, .filter_common_list:focus {
    border: 2px solid #000 !important;
}

.dropdown1:focus-within {
    /* border: 1px solid #000 !important; */
    box-shadow: 0 0 0 2px #000;
}

.side_filter_btn:focus {
    border: 2px solid #707070 !important;
}

/* Social Section */

.social_icons {
    position: fixed;
    top: 190px;
    background-color: white;
    padding: 10px 8px 0px 5px;
    z-index: 55;
    margin-left: -43px;
    border-radius: 6px;
}

.social_icons_embedded {
    top: 20px !important;
}

.social_icons ul {
    list-style: none;
    text-align: center;
    margin-bottom: 7px;
    margin-top: -1px;
	margin-left: 5px;				 
}

.social_icons ul li a {
    font-size: 20px;
}

.hidden_image {
    position: absolute;
}


.hidden_image.side_filter {
    padding: 7px 21px;
    left: 0;
}

.hidden_image.popup_options {
    padding: 14px 11px;
    left: 0;
    top: 0;
}

.hidden_image.popup_options {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

.social-share-email{
   margin-top: -3px!important;
}

.social-share-twitter{
    margin-top: -3px!important;
}

.social-share-facebook{
  margin-bottom: -2px!important;
}

.social-share-linkedin{
	margin-bottom: -4px!important;
}

.social-share-email  a {
   background-color: #a1331a;
    color: #fff;
    padding: 0 3px 0px;
    border-radius: 5px;
}


.social-share-twitter  a {
    color: #4dc1e0;
}

.social-share-facebook  a {
    color: #1781d3;
}


.social-share-email  a i{
    line-height: 22px;
    margin-top: -10px;
	font-size: 23px;
}

.social-share-twitter  a i {
    line-height: 27px;
    font-size: 33px;
}

 
.social-share-facebook  a i {
    line-height: 28px;
    margin-top: 0px;
    font-size: 33px;
}

.social-share-linkedin  a i {
    line-height: 22px;
    margin-top: 7px;
    font-size: 33px;
}


						

.social_icons ul li {
    margin-bottom: 8px;
}					 
.social_icons ul li:last-child a {
    color: #1781D3;
}




/* About Page */

.about {
    padding: 70px 0;
}

.about .about_inner h3 {
    font-size: 34px;
    font-weight: 600;
    color: #45a09f;
    margin: 0 0 20px 0;
}

.about .about_inner p {
    font-size: 20px;
    font-weight: lighter;
}

.about .about_inner p:last-child {
    margin: 0;
}

.footer_content {
    padding-top: 2px;
}

.research_content iframe{
    border: none;
}

.footer_content h1,
.footer_content h2,
.footer_content h3,
.footer_content h4,
.footer_content h5,
.footer_content h6 {
    color: #153180;
    margin-top: 10px;
    font-weight: 600;
    line-height: 27px;
    padding-top: 0px;
    margin-bottom: 10px;
    font-family: 'Open Sans', sans-serif;
}

.footer_content h1 {
    font-size: 18px;
}

.footer_content h2 {
    font-size: 17px;
}

.footer_content h3 {
    font-size: 16px;
}

.footer_content h4 {
    font-size: 15px;
}

.footer_content h5 {
    font-size: 14px;
}

.footer_content h6 {
    font-size: 13px;
}

.footer_content p {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 15px;
    font-family: 'Open Sans', sans-serif;
}

.side_filter_btn {
    cursor: pointer;
}

.search_result {
    height: 800px;
}

@-moz-document url-prefix() {
	.search_result {
		height: 1340px;
	}
}							 
.banner_description svg {
    margin-top: 8px;
}

.css-1gtu0rj-indicatorContainer {
    display: none !important;
}

div.needHeight {
    height: 21px;
}

.egmMapPage .banner_body a {
    color: #60a2e9;
    text-decoration: none!important;
    font-style: normal!important;
}

.egmMapPage .banner_description a {
    color: #60a2e9;
    text-decoration: none!important;
    font-style: normal!important;
}

.egmMapPage .footer_content a {
    color: #000;
    text-decoration: none!important;
    font-style: normal!important;
}

.egmMapPage .tab-pane a {
    color: #418bdb;
    text-decoration: none!important;
    font-style: normal!important;
}

.egmMapPage .footer_content p a {
    color: #418bdb;
}

.egmMapPage .research_report a {
    text-decoration: none!important;
}



/* Responsive */
@media (min-width: 1300px) {
    /* .filter_common_condition.filter_condition_row label, .search_dropdown ul li .dropdown1 {
        width: 67%;
    } */
}
@media (min-width: 1600px) {
    /* .filter_common_condition.filter_condition_row label, .search_dropdown ul li .dropdown1 {
        width: 75%;
    } */

    .filter_condition {
        width: 17% !important;
        min-width: 65px !important;
        max-width: 65px !important;
    }
}

@media (max-width: 1852px) {
    .search_dropdown ul li {
        flex: 0 0 25%;
        max-width: 25%;
        margin: 0 0 10px 0;
        padding: 0 12px;
    }
}
@media (min-width: 1852px) {
    .search_dropdown ul li {
        flex: 0 0 20%;
        max-width: 20%;
        margin: 0 0 10px 0;
        padding: 0 12px;
    }
    /* .filter_common_condition.filter_condition_row label, .search_dropdown ul li .dropdown1 {
        width: 73%;
    } */
}
@media (min-width: 2020px) {
    /* .filter_common_condition.filter_condition_row label, .search_dropdown ul li .dropdown1 {
        width: 85%;
    } */
}
@media (min-width: 2020px) and (max-width: 2760px) {
    /* .filter_common_condition.filter_condition_row label, .search_dropdown ul li .dropdown1 {
        width: 74%;
    } */
    .css-12jo7m5{
        flex: 0 0 90% !important;
    }
}
@media (min-width: 767px) and (max-width:1200px) {
    .search_dropdown ul li {
        flex: 0 0 33% !important;
        max-width: 33% !important;
        margin: 0 0 10px 0;
        padding: 0 12px;
    }
}
@media (max-width: 1200px) {
    .search_dropdown ul {
        align-items: flex-end;
        max-width: 100% !important;
    }
    .css-1wa3eu0-placeholder,
    .search_dropdown ul li select {
        font-size: 14px;
    }
}

@media (max-width: 1200px) {
    .search_dropdown ul li {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .filter_common_list {
        width: 13% !important;
    }

    .uc_container {
        max-width: 100% !important;
        width: 100% !important;
        margin: 10px 0px 4px 0px !important;
    }
    .filter_common_condition.filter_condition_row label, .search_dropdown ul li .dropdown1{
        width: calc(100% - 70px);
        max-width: inital;
    }
}

@media (max-width: 767px) {
    .search_dropdown ul li {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .filter_common_list {
        width: 50% !important;
        margin-left: 0;
    }
    /* .filter_common_condition.filter_condition_row label, .search_dropdown ul li .dropdown1{
        width: 74% !important;
        max-width: inital;
    } */
}

@media (max-width: 620px) {
    .search_dropdown ul li {
        flex: 0 0 100%;
        max-width: 100%;
    }
    
    .filter_common_list {
        width: 40% !important;
    }
}

@media only screen and (max-width: 1300px) {
    .banner_content h1 {
        font-size: 38px;
        line-height: 40px;
    }
    .modification_date p {
        color: #fff;
        margin: 0;
    }
    .search_dropdown ul button {
        margin: 0 10px;
        padding: 0px 18px;
        margin-bottom: 0;
    }
    .search_result>ul li {
        margin: 0;
        text-align: left;
        max-width: 33% !important;
        flex: 0 0 33% !important;
        margin-bottom: 5px;
    }

    /* .filter_common_condition.filter_condition_row label, .search_dropdown ul li .dropdown1 {
        width: 67%; */
        /* max-width: 67%;
    } */
    /* .filter_common_condition.filter_condition_row label, .search_dropdown ul li .dropdown1.filter_without_condition {
        max-width: 100% !important;
        width:100% !important;
    } */

    .filter_condition {
        width: 23% !important;
        min-width: 65px !important;
        max-width: 65px !important;
    }
}
@media only screen and (max-width: 1200px) {
    .filter_condition {
        width: 22% !important;
        min-width: 65px !important;
        max-width: 65px !important;
    }
    .filter_common_condition{
        display: flex !important;
	    padding-right: 20px;
		/* padding-right: 20px; Need to confirm */
    }
    .fc_label_container{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: .6rem .5rem;
        margin-left: 16px;
    }
    .fc_label_container label{
        font-size: 1rem;
    }
    .uc_container select{
        width: 100% !important;
        min-height: 34px !important;
    }
    .uc_container{
        padding: 16px 0px 18px 0px !important;
    }
    .needHeight{
        height: unset !important;
    }    
   
}
@media only screen and (max-width: 767px) {
    .css-1rhbuit-multiValue{
        margin: 1px !important;
    }
    .css-g1d714-ValueContainer{
        padding: unset !important;
        /* max-height: 29px !important; */
    }

}
.css-1rhbuit-multiValue{
    margin: 1px !important;
    /* max-width: calc(100% - 5px); */
    max-width: 93%;
    /* flex-wrap: wrap; */
}
.css-g1d714-ValueContainer{
    padding: unset !important; 
    /* max-height: 31px !important; */
}

.css-1wa3eu0-placeholder{
    top: 50% !important;
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
    .filter_condition {
        width: 22% !important;
        max-width: 65px !important;
        min-width: 65px !important;
    }
}
@media only screen and (max-width: 1200px) {
    .map_section img {
        width: 100%;
    }
    .search_dropdown ul button {
        margin: 5px 10px 0;
        padding: 0px 14px;
        margin-left: 50px;
    }
    header nav ul li a {
        font-size: 16px;
    }
    header nav ul li .dropdown-menu a {
        font-size: 16px;
    }
    .banner_tab .nav-tabs li a,
    .banner_tab .nav-tabs li a.active {
        font-size: 18px;
    }
    .banner_description {
        margin-top: 50px;
        display: flex;
    }
}

@media only screen and (max-width: 992px) {
    .modification_date {
        display: flex;
        flex-wrap: wrap;
    }
    .modification_date p:last-child {
        margin-left: 0;
    }
    .search_dropdown ul button {
        padding: 0px 37px;
    }
    .banner_tab .nav-tabs li a,
    .banner_tab .nav-tabs li a.active {
        font-size: 14px;
    }
    .research_report {
        display: block;
        float: none;
        text-align: right;
    }
    .search_dropdown ul button {
        left: 50%;
    }    
}

@media only screen and (max-width: 767px) {
    .banner {
        padding: 50px 0;
    }
    .search_dropdown ul button {
        padding: 0px 10px;
        display: block;
        width: 100%;
        margin: 0 auto 0;
        font-size: 16px;
    }
    .search_result>ul li {
        max-width: 50% !important;
        flex: 0 0 50% !important;
        margin-bottom: 5px;
        padding-right: 10px;
    }
    .banner_tab ul li a {
        min-height: 59px;
    }
}

@media screen and (max-width: 575px) {
    header .navbar a {
        width: 64%;
    }
    header .navbar a img {
        width: 100%;
    }
    .banner_content h1 {
        font-size: 26px;
        line-height: 26px;
    }
    .social_icons {
        padding: 8px 6px 0px 3px;
        background-color: white;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }
    .search_dropdown ul li {
        flex: 0 0 100%;
        max-width: 100%;
        margin: 0;
        padding: 0 12px;
    }
    .search_dropdown ul button {
        margin: 0 auto;
        position: static;
        max-width: 100% !important;
    }
    .search_result>button {
        margin-left: auto;
    }
    .search_result>ul li {
        max-width: 100% !important;
        flex: 0 0 100% !important;
        margin-bottom: 5px !important;
        padding-right: 0;
    }
    .search_dropdown ul {
        padding-bottom: 0;
        align-items: center;
    }
    footer p {
        color: #fff;
        text-align: center;
        margin: 0;
        padding: 25px 10px;
        font-size: 14px;
    }
	.banner_tab .nav-tabs li:first-child a {
        border-top-left-radius: 10px!important;
        border-top-right-radius: 10px!important;
    }					   
    .banner_tab ul {
        display: flex;
        border-bottom: 0;
        /*overflow: hidden;
        overflow-x: scroll;*/
        flex-wrap: wrap;
    }
    .banner_tab ul li {
        flex: 0 0 100% !important;
        max-width: 100% !important;
		border-bottom: 2px solid #45A09F;				
    }
    .about .about_inner p {
        font-size: 18px;
        font-weight: lighter;
    }
    .fc_label_container label {
        font-size: .9rem !important;       
    }
    .fc_label_container {
        padding-left: unset !important;
    }
    .uc_container select{
        width: unset !important;
    }
    .f_vertical ul {
        padding-bottom: 1rem !important;
    }
}

.uc_btn {
    background-color: rgb(69, 160, 159);
    visibility: visible;
    font-size: 15px;
    font-weight: 600;
    border: none;
    color: #fff;
    border-radius: 4px;
    padding: 8px 18px;
}

.uc_container {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    /* margin: 20px 20px 20px 2px; */
    background-color: #dfdfdf;
    border-radius: 8px;
    max-width: 19%;
    text-align: center;
    justify-content: center;
    margin-left: 1%;
}

.uc_container select {
    /* width: 50%; */
    min-height: 23px;
    padding: 5px 0px 5px 8px;
    font-size: 14px;
    border-radius: 4px;
    font-family: 'Open Sans', sans-serif;
    /* outline: none; */
    margin-top: 5px;
    margin-bottom: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
         appearance: none;
    background-color: #fff;
    background-image: url(data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20256%20448%22%20enable-background%3D%22new%200%200%20256%20448%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E.arrow%7Bfill%3A%23424242%3B%7D%3C%2Fstyle%3E%3Cpath%20class%3D%22arrow%22%20d%3D%22M255.9%20168c0-4.2-1.6-7.9-4.8-11.2-3.2-3.2-6.9-4.8-11.2-4.8H16c-4.2%200-7.9%201.6-11.2%204.8S0%20163.8%200%20168c0%204.4%201.6%208.2%204.8%2011.4l112%20112c3.1%203.1%206.8%204.6%2011.2%204.6%204.4%200%208.2-1.5%2011.4-4.6l112-112c3-3.2%204.5-7%204.5-11.4z%22%2F%3E%3C%2Fsvg%3E%0A);
    background-position: right 8px center;
    background-repeat: no-repeat;
    background-size: auto 37%;
    padding-right: 20px;
    vertical-align: middle;
    border: none;
    max-width: 68px;
}
@media (min-width: 3840px) {
    /* .filter_common_condition.filter_condition_row label, .search_dropdown ul li .dropdown1{
        width: 85% !important;
    } */
}
.css-1hwfws3 {
    padding :2px 2px !important;
}
.f_vertical{
    flex-direction: column !important;
}
.f_vertical ul{
    max-width: 100% !important;
    width: 100%;
    /* padding-top: .09rem !important;
    padding-bottom: unset !important; */
}
@media (min-width: 640px) {
    .f_vertical .filter_common_condition {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .f_vertical .uc_container {
        max-width: 100% !important;
        margin-left: unset !important;
        flex-direction: row;
        justify-content: center;
        margin-top: .5rem;
    }
    .f_vertical .uc_container select {
        /* margin-top: unset !important; */
        margin-left: 5px;
    }
    .f_vertical .uc_container .needHeight {
        height: unset !important;
    }
    .f_vertical .uc_btn {
        margin-left: 10px;
    }
    .f_vertical .fc_label_container label {
        margin-bottom: unset !important;
    }
    .f_vertical .uc_container .fc_select_wrap {
        align-self: unset !important;
    }
	
	
	
	
}






	

.egmMapPage {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
}


/*.rct-checkbox {
    border-radius: 0;
    width: 25px;
    height: 25px;
    outline: auto;
    outline-color: #45a09f;
}*/


/* .react-checkbox-tree .rct-icon {
    display: inline;
    text-align: center;
    text-rendering: auto;
    font-family: "Font Awesome 5 Free", "FontAwesome", sans-serif;
    font-weight: normal;
    font-style: normal;
    -webkit-font-feature-settings: normal;
    font-feature-settings: normal;
    font-variant: normal;
}

.rct-collapse,
.rct-checkbox,
.rct-node-icon {
    padding: 0 5px;
    position: absolute;
    padding-right: 17px;
    padding-right: 22px;
    padding: 0;
    margin-right: 21px;
    margin-left: 351px;
}
*/

.rct-text label {
    display: inline-block;
    padding-right: 10px;
    white-space: nowrap;
}

.checkboxes input {
    vertical-align: middle;
}

.checkboxes label span {
    vertical-align: middle;
}

.slide-pane__overlay {
    position: fixed;
    top: 20%;
    left: 0px;
    right: 0px;
    bottom: unset;
    background-color: rgba(0, 0, 0, 0);
}

.slide-pane__content {
    padding: 0;
    overflow-y: visible;
}

.slide-pane__content br {
    display: none;
}

.slide-pane__header {
    display: none;
}

.ReactModalPortal .slide-pane_from_right {
    max-width: 450px;
}

@media (max-width: 575px) {
    .ReactModalPortal .slide-pane_from_right {
        max-width: 320px;
    }
}

.ReactModalPortal .slide-pane__overlay.ReactModal__Overlay--after-open {
    background-color: transparent;
    transition: background-color 0.5s;
}

.search_result>button {
    position: absolute;
    /* left: calc(100% - 87.1%); */
    top: 225px;
    right: -5%;
    background-color: #45A0A0;
    color: #fff;
}

header.MuiPaper-root {
    background-color: #77BDBD;
    padding: 0;
}

header.MuiPaper-root .MuiTabs-root button.MuiButtonBase-root.Mui-selected {
    background-color: #45A09F;
}

header.MuiPaper-root .MuiTabs-root button.MuiButtonBase-root {
    font-size: 18px;
    font-weight: 600;
    text-transform: capitalize;
    padding: 10px 50px;
    background: #77bdbd;
    border: none;
    outline: none;
}

.stop-x-scrolling {
    display:none;
}

@media (max-width: 575px) {
    header.MuiPaper-root .MuiTabs-root button.MuiButtonBase-root {
        padding: 10px 28px;
    }
}

.rct-node-parent ol li span.rct-title {
    font-weight: 200;
}

header.MuiPaper-root .MuiTabs-root button.MuiButtonBase-root:hover {
    border: none;
    background: #77bdbd;
    color: #fff;
}

.MuiBox-root-7 {
    padding: 40px 60px !important;
}

.MuiPaper-root {
    border: none !important;
}

.MuiPaper-root .MuiAccordionSummary-root {
    padding: 0;
    min-height: unset;
    background: #fff;
    border: none;
}

.MuiPaper-root .MuiAccordionSummary-root .MuiAccordionSummary-content {
    margin: 0;
}

.checkbox_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.rct-checkbox {
    display: block;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.MuiPaper-root .MuiAccordionSummary-root .MuiAccordionSummary-content p {
    width: 100%;
}

.checkbox_title h3 {
    max-width: 80%;
    flex: 0 0 80%;
    font-size: 22px;
    font-weight: 600;
}

.rct-node-icon {
    color: #33c;
    display: none;
}

.react-checkbox-tree ol .fa-chevron-up {
    margin-left: -650px;
}

.react-checkbox-tree ol ol .fa-chevron-up {
    margin-left: -610px;
}

.react-checkbox-tree ol .fa-chevron-down {
    margin-left: -650px;
}

.react-checkbox-tree ol ol .fa-chevron-down {
    margin-left: -610px;
}

.react-checkbox-tree .rct-icon {
    outline: none!important;
	width: 25px;			
}

.rct-icon-uncheck {
    border-radius: 0 !important;
    width: 25px!important;
    /* height: 25px!important;*/
    outline: auto!important;
    outline-color: #45a09f!important;
}

.rct-icon-check {
    border-radius: 0 !important;
    width: 25px!important;
    /* height: 25px!important;*/
    outline: none!important;
    outline-color: none!important;
}

.react-checkbox-tree ul {
    padding-left: 5px;
}

.rct-text {
    max-width: 80%;
    flex: 0 0 80%;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 10px;
    margin-left: 10px;
}

.rct-checkbox input {
    border-radius: 0;
    width: 25px;
    height: 25px;
    outline: auto;
    outline-color: #45a09f;
}

span#close {
    position: absolute;
    top: -9px;
    right: -20px;
    font-size: 16px !important;
    color: #000 !important;
    line-height: normal;
    border: 1px solid #aaa;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    font-weight: 700 !important;
    font-family: 'Open Sans', sans-serif !important;
}

.modalContentDefinition span#closeDefinition {
    position: absolute;
    top: -9px;
    right: -20px;
    font-size: 16px !important;
    color: #000 !important;
    line-height: normal;
    border: 1px solid #aaa;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    font-weight: 700 !important;
    font-family: 'Open Sans', sans-serif !important;
}

.modalContentNotification span#closeNotification {
    position: absolute;
    top: -9px;
    right: -20px;
    font-size: 16px !important;
    color: #000 !important;
    line-height: normal;
    border: 1px solid #aaa;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    font-weight: 700 !important;
    font-family: 'Open Sans', sans-serif !important;
}												  
.checkbox_title input {
    border-radius: 0;
    width: 25px;
    height: 25px;
    outline: auto;
    outline-color: #45a09f;
}

.MuiTypography-body1 {
    width: 100%;
}

.accordion_checkboxes ul li {
    /*display: flex;*/
    align-items: center;
    justify-content: space-between;
    list-style: none;
    margin-bottom: 15px;
}

.accordion_checkboxes ul li input {
    border-radius: 0;
    width: 25px;
    height: 25px;
    outline: auto;
    outline-color: #45a09f;
    margin-right: -86px
}

.MuiAccordionDetails-root {
    padding: 5px 0 0 30px !important;
}

.accordion_checkboxes ul li p {
    font-size: 20px;
    color: #888;
    /*max-width: 80%;*/
    flex: 0 0 80%;
    font-weight: 300;
}

.MuiAccordion-root {
    margin-bottom: 15px;
}

.rct-node-clickable {
    display: flex;
}

.rct-node-clickable:hover {
    font-weight: 600!important;
}

.tab_submit_btns {
    background-color: #485464;
    padding: 8px 60px;
    text-align: right;
    margin-top: 10px;
}

@media (max-width: 575px) {
    .tab_submit_btns {
        padding: 8px 20px;
    }
	.react-checkbox-tree ol .fa-chevron-up,
    .react-checkbox-tree ol .fa-chevron-down {
        margin-left: 0;
        position: absolute;
        left: -5px;
        top: 5px;
    }
    .react-checkbox-tree ol ol .fa-chevron-up,
    .react-checkbox-tree ol ol .fa-chevron-down {
        margin: 0;
    }										
}

.rct-icons-fa4 .rct-icon-uncheck::before {
    margin-left: -3px;
}

.tab_submit_btns button {
    padding: 3px 30px;
    font-size: 16px;
    background: inherit;
    border: none;
    color: #fff;
    margin-left: 5px;
}

.tab_submit_btns button.submit_btn {
    background-color: #45A09F;
}


/* chart */

#chartModal {
    max-height: 490px !important;
    transform: translate(-50%, -50%);
    top: 50% !important;
    left: 50% !important;
    background-color: #fff !important;
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
}

#chartModalDefinition {
    max-height: 490px !important;
    transform: translate(-50%, -50%);
    top: 50% !important;
    left: 50% !important;
    background-color: #fff !important;
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
}

#chartModalNotification {
    max-height: 490px !important;
    transform: translate(-50%, -50%);
    top: 50% !important;
    left: 50% !important;
    background-color: #fff !important;
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
}							
							
.modalContent {
    margin: 0 !important;
    width: 100% !important;
    padding: 0 !important;
}

.modalContent,
.popup_right_section {
    display: grid;
    vertical-align: top;
}

.modalContentDefinition {
    margin: 0 !important;
    width: 100% !important;
    padding: 0 !important;
    display: grid;
    vertical-align: top;
}

.modalContentNotification {
    margin: 0 !important;
    width: 100% !important;
    padding: 0 !important;
    display: grid;
    vertical-align: top;
}
						  
.modalContentDefinition {
    background: #707070!important;
    border-radius: 8px;
    color: #ffffff!important;
    border: 1px dotted #707070;
}

.modalContentNotification {
    background: #ffffcc!important;
    border-radius: 8px;
    color: #ffffff!important;
    border: 1px dotted #707070;
						
}							   
#myModalDefinition,
.modalDefinition {
    border-radius: 15px;
}

#myModalNotification,
.modalNotification {
    border-radius: 15px;
}					 
.parent-level-0-odd {
    background-color: #dfdfdf;
    font-size: 13px!important;
    /* font-weight: 600!important; */
    font-family: 'Open Sans', sans-serif!important;
    color: #000000!important;
}

.parent-level-0-even {
    background-color: #c6c6c6;
    font-size: 13px!important;
    /* font-weight: 600!important; */
    font-family: 'Open Sans', sans-serif!important;
    color: #000000!important;
}

.parent-level-1-odd {
    background-color: #ffffff;
    font-family: 'Open Sans', sans-serif!important;
    font-size: 13px!important;
    font-weight: 400!important;
}

.parent-level-1-even {
    background-color: #ffffff;
    font-family: 'Open Sans', sans-serif!important;
    font-size: 13px!important;
    font-weight: 400!important;
}

.parent-level-2-odd {
    font-family: 'Open Sans', sans-serif!important;
    background-color: #f7f7f7;
    font-size: 13px!important;
    font-weight: 400!important;
}

.parent-level-2-even {
    font-family: 'Open Sans', sans-serif!important;
    background-color: #f7f7f7;
    font-size: 13px!important;
    font-weight: 400!important;
}

tr.outlevel1 th {
    font-weight: 500 !important;
}

.tbody.map_tbody>tr td svg {
    line-height: 0!important;
}

* .dropdown1 {
    outline: none !important;
}

.popup_right_section {
    /*max-width: 26%; */
    width: 60%;
    background-color: #F0F0F0;
}


/*.recordList ul li {
    font-size: 14px;
    text-align: left;
    padding: 15px;
    padding-bottom: 0;
    max-width: 100%;
    flex: 0 0 100%;
    margin: 0;
}*/

.recordList ul li {
    font-size: 14px;
    text-align: left;
    padding: 15px;
    padding-bottom: 0;
    max-width: 100%;
    flex: 0 0 100%;
    margin: 0;
    background-color: #F0F0F0;
}

.recordList ul li:hover {
    background-color: #fff;
}

.recordList ul {
    margin-bottom: 0;
    list-style: none;
    padding: 0;
    background-color: #ffffff;
}

.breadcrumb_part {
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
}

.recordList ul li:after {
    content: '';
    border-bottom: 1px solid #5DD2D2;
    display: block;
    margin-top: 10px;
}

.recordList {
    font-size: 13px;
    text-align: center;
    /* color: #455566; */
    margin: 0;
    /* background-color: #F0F0F0; */
    height: 380px;
    overflow: hidden;
    overflow-y: scroll;
    overflow-y: overlay;
}


/* toggle button side filter */

@media (max-width: 450px) {
    .side_close_btn {
        border-top-left-radius: 20px !important;
        border-top-right-radius: 20px !important;
        border-bottom-left-radius: 0 !important;
        top: -8% !important;
        left: 0 !important;
    }
}

button.side_filter_btn {
    border: none;
    /* background: #45a09f;
    color: #fff;*/
    padding: 4px 25px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    outline: none;
	z-index: 51;		   
}

.slide-pane__content button.side_filter_btn {
    border: none;
    background: #45a09f;
    color: #fff;
    padding: 14px 15px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    position: absolute;
    outline: none;
    top: 50%;
    z-index: 1;
    left: -51px;
    transform: translate(0, -50%);
}

.slide-pane__content button.side_filter_btn:before {
    display: none;
}

button.side_filter_btn:before {
    content: '\f00c';
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    right: 5px;
    bottom: 10px;
}

button.side_filter_btn i {
    margin-left: 0;
    font-size: 27px;
    font-weight: 400;
}


/* Tree */

.slide-pane__content .react-checkbox-tree ol li {
    margin-bottom: 20px;
}

.slide-pane__content span.rct-checkbox {
    position: static;
    margin: 0;
    outline: none;
}

.slide-pane__content button.rct-collapse {
    position: static;
    margin: 0;
    outline: none;
}

.slide-pane__content span.rct-text {
    max-width: 100%;
    flex: 0 0 100%;
    flex-direction: row-reverse;
    position: relative;
    justify-content: flex-end;
}

.slide-pane__content span.rct-node-clickable {
    max-width: 80%;
    flex: 0 0 80%;
    background: none!important;
    font-weight: 600!important;
	align-items: center;
}

.slide-pane__content span.rct-node-clickable:focus {
    background-color: #e2fdfd !important;
}

.slide-pane__content span.rct-title {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    margin-left: 10px;
}

.slide-pane__content span.rct-title {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    margin-left: 10px;
}

.slide-pane__content span.rct-text label:hover {
    background-color: #fff;
}

.slide-pane__content .MuiBox-root {
    max-height: 450px;
    overflow-y: scroll;
}

.slide-pane__content span.rct-text label {
    position: absolute;
    right: 0;
}

.change__order span#amount_sort {
    position: relative;
    background: #5DD2D2;
    padding: 15px 20px;
}

.change__order span#amount_sort #sort_options {
    position: absolute !important;
    z-index: 1;
    overflow: unset !important;
    z-index: 7;
    bottom: 52px;
    min-width: 150px;
    left: -90px;
    background-color: #fff !important;
}

.change__order span#amount_sort #sort_options ul {
    list-style: none;
    margin: 0;
    border: 1px solid #dfdfdf;
    padding: 0;
    background-color: #ffffff;
}

.change__order span#amount_sort #sort_options ul ul {
    border: none;
    width: 100%;
}

.change__order span#amount_sort #sort_options ul li {
    color: #45A0A0;
    font-size: 13px;
    padding: 5px 10px;
    max-width: 100%;
    flex: 0 0 100%;
    margin: 0;
}

.change__order span#amount_sort #sort_options ul li:hover {
    background-color: #f0f0f0;
    padding: 5px 10px;
}

/* .td-boxes {
  width: 120px;
  height: 120px;
} */

				  
#download {
    /* margin-left:1315px; */
}

/* @media (max-width: 1024px) {
    .search_result>button {
        left: calc(100% - 95%);
    }
} */

/* @media (max-width: 575px) {
    .search_result>button {
        left: calc(100% - 48px);
    }
} */

/* Scrolling Specific */



/* width */
#foreignObjectMain div::-webkit-scrollbar {
  width: 20px;
}

/* Track */
#foreignObjectMain div::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
#foreignObjectMain div::-webkit-scrollbar-thumb:horizontal {
  background: #45A09F; 
  border-radius: 10px;
 
 background: linear-gradient(270deg, rgba(255,255,255,1) 0%, rgba(69,160,159,1) 52%, rgba(69,160,159,1) 100%);
}


/* Handle */
#foreignObjectMain div::-webkit-scrollbar-thumb:vertical  {
  background: #45A09F; 
  border-radius: 10px;
 background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(69,160,159,1) 52%, rgba(69,160,159,1) 100%);
}

/* Handle on hover */
#foreignObjectMain div::-webkit-scrollbar-thumb:horizontal:hover {
  background: #328383; 
  background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(69,160,159,1) 52%, rgba(69,160,159,1) 100%);

}

/* Handle on hover */
#foreignObjectMain div::-webkit-scrollbar-thumb:vertical:hover {
  background: #328383; 
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(69,160,159,1) 52%, rgba(69,160,159,1) 100%);

}


/* 
@media (max-width: 575px) {
    #foreignObjectLegends td>div {
        width: 50%;
        text-align: left;
    }
}

@media (max-width: 450px) {
    #foreignObjectLegends td>div {
        width: 55%;
        text-align: left;
    }
}
*/




		

.search_loader {
    text-align: center;
}

.css-yk16xz-control {
    width: 206px;
    height: auto;
    overflow-y: scroll;
    overflow: hidden;
}

.search_dropdown ul button {
    /*position: absolute; */
    margin-left: 0;
    bottom: 35px;
    left: 26%;
    margin-right: 0;
    padding: 0;
    width: 100%;
	border-radius: 5px;							   
}

th.th_Level.\33 \.4,
th.th_Level.\33 \.3 {
    /* background-color: white !important;*/
}

.css-1wa3eu0-placeholder {
    font-size: 14px;
    color: #000 !important;
    left: 0% !important;
	padding-top: 4px;			  
}

.banner_tab ul li:empty {
    display: none;
}

.search_dropdown ul li select {
    font-size: 14px;
	border-radius: 5px;					   
}

span.css-1okebmr-indicatorSeparator {
    display: none;
}

.css-tlfecz-indicatorContainer {
    padding: 0;
}

.css-tlfecz-indicatorContainer:after {
    color: #000;
    content: '\f0d7';
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 14px;
}

.css-tlfecz-indicatorContainer svg {
    display: none;
}

span.css-1okebmr-indicatorSeparator {
    display: none;
}

.css-tlfecz-indicatorContainer {
    padding: 0 !important;
    margin-top: 2px;
}

.search_dropdown ul li .dropdown1 {
    min-height: 31px;
    height: auto;
}

.search_dropdown ul {
    align-items: center;
    position: relative;
}

.search_top_filter {
    padding: 10px!important;
    /* margin: 20px 2px 20px 20px !important; */
}

.filter_condition_note {
    padding-bottom: 20px!important;
    padding-left: 20px!important;
    padding-top: 0px!important;
	margin-top:-12px!important;						
}

.filter_condition_note li {
    width: 100% !important;
    max-width: 100% !important;
    flex: 0 0 100% !important;
    margin-bottom: 0px !important;
}

.filter_condition_note li label {
    font-size: 12px !important;
    font-style: italic !important;
}

.css-1hb7zxy-IndicatorsContainer .css-tlfecz-indicatorContainer:first-child {
    display: none;
}

.css-g1d714-ValueContainer {
    padding: 0;
    display: flex;
    max-height: unset;
}

.css-12jo7m5 {
    font-size: 13px!important;
    padding-left: 2px !important;
    /* max-width: 78%;
    flex: 0 0 79%; */
}
.css-xb97g8{
    flex: 0 0;
}


/* @media only screen and (max-width: 1400px) {
    .search_dropdown ul li {
        flex: 0 0 16.3%;
        max-width: 16.3%;
    }
}

@media only screen and (max-width: 1400px) {
    .search_dropdown ul li {
        flex: 0 0 18.3%;
        max-width: 18.3%;
    }
} */

@media (max-width: 575px) {
    .search_dropdown ul li {
        max-width: 100%;
        flex: 0 0 100%;
		margin-top: 7px;
		margin-bottom: 5px;		  
    }
    .search_dropdown ul button {
        max-width: 180px;
        top: 0;
        margin: 0 auto;
    }

    .legend-list-items {
        display: block !important;
        flex: 0 0 100%;
        max-width: 100%;
    }
}

table: {
    border: 1px solid #45A09F;
    border-collapse: collapse;
    text-align: center;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
}

td: {
    border: 1px solid #45A09F;
    border-collapse: collapse;
    text-align: center;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
}

th: {
    border: 1px solid #45A09F;
    border-collapse: collapse;
    text-align: center;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
}


/* Modal Content (image) */

.modalContent: {
    margin: auto;
    display: flex;
    width: 80%;
    max-width: 700px;
}

.modalContentDefinition: {
    margin: auto;
    display: flex;
    width: 80%;
    max-width: 700px;
}

.modalContentNotification: {
    margin: auto;
    display: flex;
    width: 80%;
    max-width: 700px;
}							
.close: {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
}

.closeDefinition: {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
}

.closeNotification: {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
}					
.egmMapPage {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
}

th.intervention_outcome_label,
.outlevel3>th:first-child {
    background-color: #45A09F;
    color: #fff;
    padding: 5px;
    font-size: 20px;
    font-weight: 600;
    text-transform: capitalize;
    transform: unset;
    text-align: center;
}

tr.outlevel1 th:nth-child(odd) {
    /*  background-color: #eaeaea;*/
}

tr.outlevel1 th {
    padding: 5px;
    font-size: 18px;
    font-weight: 600;
}

/* .outlevel1 .parent-level-0-even {
    border-left: 1px solid rgb(69, 160, 159);
    border-right: 1px solid rgb(69, 160, 159);
} */

/* .outlevel2 .parent-level-1-even {
    border-left: 1px solid rgb(69, 160, 159);
    border-right: 1px solid rgb(69, 160, 159);
} */

/* .outlevel3 .parent-level-2-even {
    border-left: 1px solid rgb(69, 160, 159);
} */

/* tr.outlevel1 th {
    border-bottom: 1px solid rgb(69, 160, 159);
} */

tr.outlevel2 th {
    background-color: #fff;
    padding: 5px;
    font-size: 15px;
    font-weight: 300;
    color: #000000;
    text-align: center;
}

tr.outlevel3 th {
    /* transform: rotate(-90deg); */
    padding: 5px;
    font-size: 15px;
    font-weight: 300;
    color: #000000;
    text-align: center;
}

tr.outlevel1 th:nth-child(even) {
    /* background-color: #d2d2d2; */
}


/* 
tbody.map_tbody>tr:nth-child(odd) th:first-child {
    background-color: #C6C6C6;
    padding: 0 10px;
    font-size: 18px;
    font-weight: 600;
    text-transform: capitalize;
}

tbody.map_tbody>tr:nth-child(even) th:first-child {
    background-color: #DFDFDF;
    padding: 0 10px;
    font-size: 20px;
    font-weight: 600;
    text-transform: capitalize;
}

tr.row1 th:nth-child(2), 
tr.row4 th:first-child, 
tr.row8 th:nth-child(2), 
tr.row11 th:first-child, 
tr.row13 th:nth-child(2), 
tr.row15 th:first-child, 
.row17 th:nth-child(2), 
.row19 th:first-child, 
tr.row20 th:nth-child(2) {
    transform: rotate(-90deg);
    font-size: 15px !important;
    font-weight: 300 !important;
    color: #888;
    background-color: unset !important;
    padding: 0 0 !important;
    width: 80px;
    height: 100px;
}

tr.row1 th:nth-child(3),
tr.row2 th:first-child,
tr.row3 th:first-child,
tr.row4 th:nth-child(2),
tr.row5 th:first-child,
tr.row6 th:first-child,
tr.row7 th:first-child,
tr.row8 th:nth-child(3),
tr.row9 th:first-child,
tr.row10 th:first-child,
tr.row11 th:nth-child(2),
tr.row12 th:first-child,
tr.row13 th:nth-child(3),
tr.row14 th:first-child,
tr.row15 th:nth-child(2),
tr.row16 th:first-child,
tr.row17 th:nth-child(3),
tr.row18 th:first-child,
tr.row19 th:nth-child(2),
tr.row20 th:nth-child(3),
tr.row21 th:first-child,
tr.row22 th:first-child {
    padding: 5px 10px;
    text-align: center;
    width: 120px;
    color: #888;
    font-weight: 400 !important;
    background-color: unset !important;
    font-size: 13px !important;
} */

thead.map_thead tr .header_count {
    /* border: 1px solid #45A09F; */
    padding: 5px 30px !important;
    font-weight: 600;
    font-size: 16px;
}

thead.map_thead tr .header_count p {
    color: #45a09f;
    font-size: 30px;
    font-weight: 600;
    margin: 0;
}

.egmMapPage i {
    font-family: 'FontAwesome';
    font-style: normal;
}


/* breadcrumb */

.breadcrumb_part {
    background-color: #485464;
}

.breadcrumb_part>div {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #fff;
    padding: 20px;
}

.breadcrumb_part>div h6,
.breadcrumb_part>div i,
.breadcrumb_part>div p {
    font-size: 14px;
    color: #fff;
    margin: 0;
}

.breadcrumb_part>div i {
    margin: 0 10px;
}

.breadcrumb_part>div h6 {
    font-weight: bold;
}

.popup_right_section label {
    display: block;
    /*background-color: #B4D2EE;*/
    padding: 0 0;
    font-size: 20px;
    font-weight: bold;
    color: #455566;
    text-align: center;
    display: flex;
    margin: 0;
}

.popup_right_section h6 {
    font-size: 24px;
    text-align: center;
    color: #455566;
    margin: 30px 0;
}

.popup_right_section .change__order {
    background-color: #45A09F;
    display: flex;
    justify-content: space-between;
}

.popup_right_section .change__order i {
    color: #fff;
}

.change__order span#amount_sort {
    position: relative;
}

.change__order span#span_export {
    position: relative;
    background: #5DD2D2;
    padding: 15px 20px;
}

.some-custom-overlay-class {
    position: absolute !important;
    left: unset !important;
    right: 0 !important;
    /* top: calc(100% + 535px) !important;*/
    top: calc(100% + 535px);
    bottom: unset !important;
    z-index: 52;
}

.top_filter_btn {
    /* visibility: hidden; */
}

.drop_down {
    position: absolute;
    right: 0px;
    background: #fff;
    border: 1px solid #888;
    max-width: 300px;
    top: 45px;
    box-shadow: 0 0 20px 5px rgb(0 0 0 / .1);
    z-index: 55;
}

.drop_down p {
    color: #888;
    margin: 0;
    padding: 10px 0 10px 30px;
    font-size: 18px;
    border-bottom: 1px solid #45A09F;
}

.drop_down ul {
    background: #fff;
    padding: 15px 30px;
}

.drop_down ul li {
    margin: 0;
    display: block;
    max-width: unset;
    width: 100%;
    flex: unset;
    padding: 5px 0;
}

.drop_down ul li a {
    color: #888;
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
}

.download, .zoom_icon_minus, .zoom_icon_plus {
    padding: 0px 8px;
    font-size: 23px;
    line-height: normal;
    border: 1px solid #000;
    cursor: pointer;
    /*/ display: block; /*/
    /* margin-left: calc(100% - 40px); */
    margin-bottom: 6px;
    /*/ margin-left: auto; /*/
    width: 38px;
    background-color: #dfdfdf;
    float: right;
	border-radius: 4px;		
margin-left:10px;	
}


#zoom_icon_minus {
    background-color: #ffffff;
    border: none;
    color: #45a09f;
	margin-left: 0px;
}

#zoom_icon_plus {
    background-color: #ffffff;
    border: none;
    color: #cccccc;
	margin-left: 0px;
}



.search_result {
    padding: 6px 0!important;
    position: relative!important;
}

.spacing {
    display: block;
    width: 100%;
    background-color: #45A0A0;
}

div#chartZoom g.zoomNode:last-child {
    /* transform: translate(110px, 20px); */
}

.download-studies {
    position: absolute;
    right: 0;
    display: none;
    bottom: 53px;
    left: unset;
}

.popup_right_section {
    /* max-width: 26%; */
    width: 60%;
    background-color: #F0F0F0;
}

.change__order span#amount_sort #sort_options {
    position: absolute !important;
    z-index: 1;
    overflow: unset !important;
    z-index: 7;
    bottom: 52px;
    min-width: 150px;
    left: -90px;
    /* background-color: #fff !important; */
	background-color: transparent!important;
}


/*.download-studies{
    position: absolute;
    left: 573px;
    display: none;

}*/

.download-studies ul {
    background-color: #fff !important;
    padding: 0px;
    flex-direction: column;
    align-items: flex-start;
    border: 1px solid #dfdfdf;
}

.recordList ul li a {
    color: #455566;
    text-decoration: none;
}

.download-studies li {
    margin: 0 1px !important;
    width: 145px !important;
    max-width: 100% !important;
    cursor: pointer;
    padding: 3px 10px;
    /*border-bottom: 1px solid #D9D9D9;*/
}

.download-studies a {
    color: #45A0A0 !important;
    font-size: 13px !important;
    font-weight: 400;
    text-decoration: none;
}

.download-studies li:hover {
    background-color: #ececec;
    text-decoration: none;
}

tbody.map_tbody>tr.row17 th:first-child {
    /* background-color: #dfdfdf;*/
}

@media (max-width: 1537px) {
    .breadcrumb_part>div h6,
    .breadcrumb_part>div i,
    .breadcrumb_part>div p {
        font-size: 15px;
    }
}

@media (max-width: 1464px) {
    .breadcrumb_part>div h6,
    .breadcrumb_part>div i,
    .breadcrumb_part>div p {
        font-size: 14px;
    }
    .search_result #myModal {
        max-width: 700px;
        width: 100%;
    }
}

@media (max-width: 800px) {
    .search_result #myModal {
        max-width: 630px;
        width: 100%;
    }
}

@media (max-width: 767px) {
    .search_result #myModal {
        max-width: 520px;
        width: 100%;
    }
    .breadcrumb_part>div {
        padding: 10px;
    }
    .breadcrumb_part>div h6,
    .breadcrumb_part>div i,
    .breadcrumb_part>div p {
        font-size: 11px;
    }
    .recordList ul li {
        font-size: 13px;
    }

    #title-div {
        width: 100%;
    }
}

@media (max-width: 767px) {
    .modalContent,
    .popup_right_section {
        width: 100% !important;
        display: flex;
        flex-wrap: wrap;
    }
    #chartModal {
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }
    .breadcrumb_part,
    .popup_right_section label,
    .popup_right_section .change__order {
        width: 100%;
    }
    .change__order span#amount_sort #sort_options {
        left: 0;
    }
}

#title-div {
    background-color: #8DC1F1;
    align-items: center;
    display: flex;
    padding: 10px 5px;
}

#study_count {
    color: black;
    font-size: 14px;
    margin: 0 5px;
    background-color: white;
    padding: 5px 5px;
    border-radius: 10px;
    width: 35px;
    height: 35px;
    text-align: center;
    display: none;
    font-weight: 600;
    justify-content: center;
    align-items: center;
}

#main_map {
    background-color: white !important;
}

.print_button {
    cursor: pointer;
}

@page {
    size: auto;
    size: A0;
    margin: 1mm;
}


/*@media print {
    .no-print,
    .no-print * {
        display: none !important;
    }
    .show-print,
    .show-print * {
        display: inline-block !important;
    }
    .table td.print_bg,
    .table th.print_bg {
        background-color: #b2b2b2 !important;
    }
    .table td.print_bg2,
    .table th.print_bg2 {
        background-color: #f7f7f7 !important;
    }
    .badge {
        border-color: none !important;
        border: 1px solid #fff !important;
    }
    .table td .badge {
        border-color: none !important;
        border: 1px solid #fff !important;
    }
    .content-wrapper {
        background: #fff !important;
    }
    .search_dropdown{
        display: none !important;
    }
    #download{
        display: none !important;
    }
}*/

@media print {
    .no-print,
    .no-print * {
        display: none !important;
    }
    header {
        display: none !important;
    }
    footer {
        display: none !important;
    }
    .banner {
        display: none !important;
    }
    .banner {
        display: none !important;
    }
    #main_map,
    foreignObject,
    #map_table {
        width: 800px !important;
    }
    iframe {
        display: none !important;
    }
    .loadingWave,
    .side_filter_btn,
    #myModal,
    .button {
        display: none !important;
    }
}

table.map_table td, table.map_table th {
    box-shadow: 0px 0px 0px 0px #fffefe, 1px 0px 0px 0px #fffefe;
}


/*@media print {
  body * {
    visibility: hidden;
  }

  #main_map {
    display: inline-block !important;*/


/*position: absolute;*/


/*left: 0;
    top: 0;
  }
}*/


/*
tbody.map_tbody>tr:nth-child(odd) th:first-child, 
tbody.map_tbody>tr:nth-child(even) th:first-child {*/

tbody.map_tbody>tr th {
    padding: 2px 10px;
    text-align: center;
    color: #000000;
    font-weight: 400;
    font-size: 13px;
    min-height: 120px;
    align-items: center;
}

tbody.map_tbody>tr th:nth-child(1) {
    /* transform: rotate(-90deg); */
    /*max-width: 200px;*/
    /* padding: 10px; */
    /*padding: 0 59px;
    word-break: break-all;*/
    /* border: 1px solid #45A09F; */
}

tbody.map_tbody>tr th:nth-child(2) {
    /* transform: rotate(-90deg); */
    /* max-width: 150px;*/
    /* padding: 10px; */
    /* word-break: break-word;*/
    /* border: 1px solid #45A09F; */
}

tbody.map_tbody>tr th.parent-level-undefined:nth-child(2) {
    /* transform: rotate(-90deg);*/
    /* max-width: 150px;*/
    /* padding: 10px; */
    /* word-break: normal; */
}

tbody.map_tbody>tr th.th_Level {
    transform: none;
    padding: 10px;
    /* background-color: #f7f7f7;*/
}

tbody.map_tbody>tr th.parent-level-0:parent {
    background-color: #dfdfdf;
}

div.tooltip {
    position: absolute;
    text-align: center;
    width: 200px;
    padding: 6px;
    font: 12px sans-serif;
    background: #707070;
    border-radius: 8px;
    pointer-events: none;
    color: #ffffff;
    border: 1px dotted #707070;
}

#print_custom_note {
    width: 300px;
    right:42px;
}

#side_filter_custom_note {
    width: 300px;
    right:77px;
}

.circle_icon {
    background: #f00;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    color: #f00;
}

.box_icon {
    background: #f7f7f7;
    width: 18px;
    height: 18px;
    color: #f7f7f7;
}

.grid_bg {
    background: linear-gradient(-90deg, rgba(158, 158, 158, .5) 1px, transparent 1px), linear-gradient(rgba(158, 158, 158, .5) 1px, transparent 1px), #ffffff;
    background-size: 4px 4px, 4px 4px, 80px 80px, 80px 80px, 80px 80px, 80px 80px, 80px 80px, 80px 80px;
    font-size: 1px;
	/*border-right: 1px solid rgb(69, 160, 159);*/										   
}

.noselect_text {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.banner_tab .nav-tabs li a {
    cursor: pointer;
}

@media (max-width: 575px) {
    .banner_tab .nav-tabs li a {
        min-height: auto;
    }
}

.search_dropdown ul li .dropdown1 .css-tlfecz-indicatorContainer:last-child {
    display: none;
}

.search_dropdown ul li .dropdown1 .css-tlfecz-indicatorContainer:first-child {
    display: none;
}

.search_dropdown ul li .dropdown1>.css-tlfecz-indicatorContainer {
    display: block;
}

.search_dropdown ul li .dropdown1 span.css-1okebmr-indicatorSeparator+.css-tlfecz-indicatorContainer {
    display: block;
}

.search_dropdown ul li .dropdown1 .css-26l3qy-menu {
    font-size: 14px;
}

.egmMapPage .banner:before {
    content: '';
    background-color: black;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.4;
    z-index: 0;
}

.egmMapPage .banner .banner_content {
    position: relative;
}

.egmMapPage .banner {
    position: relative;
}

.egmMapPage .banner h1,
.egmMapPage .banner .modification_date p,
.banner_content .banner_body p,
.banner_description span,
.banner_description span p,
.egmMapPage .research_report a {
    text-shadow: 0px 0px 3px rgb(0 0 0 / 60%);
    -webkit-filter: drop-shadow(0px 0px 100px black);
            filter: drop-shadow(0px 0px 100px black);
}

.egmMapPage .mobileBanner {
    display: none;
}

.egmMapPage .mobileBanner img {
    width: 100%;
}

@media screen and (max-width: 719px) {
    .egmMapPage .bannerHide {
        background: none !important;
    }

    .egmMapPage .mobileBanner {
        display: block;
    }
}

.project_funded_by img {
    max-width: 100%;
    height: auto;
}

.refresh_message {
    text-align: center;
    border: 1px solid #A13422;
    border-radius: 10px;
    padding: 15px;
    background: #f8f8f8;
    margin-top: 60px;
    font-weight: bold;
}

.refresh_button {
    background-color: #45a09f;
    border-radius: 5px;
    border: none;
    padding: 5px;
    width: 100px;
    color: #fff;
    cursor: pointer;
    font-weight: 600;
}

.custom_tooltip .__react_component_tooltip {
    background-color: #45A09F;
    color: #fff;
}

.custom_tooltip .__react_component_tooltip.type-dark.place-top:after {
    border-top-color: #45A09F;
    display: none !important;
}


/* .custom_tooltip .__react_component_tooltip.place-top:before {
    display: none !important;
} */
.c_tooltip:before,
.c_tooltip:after {
    display: none !important;
}

.c_tooltip {
    opacity: 1 !important;
    width: 250px;
}

.tooltip_img {
    margin-top: -3px;
}

@media (hover: none) {
    .filter_condition:hover {
        color: oldcolor;
    }
}


.recordList ul {
    max-width: 95%;
}

.search_dropdown ul li label {
    line-height: 1.0;
}

.search_top_filter {
    padding: 10px 5px 4px 5px!important;
}

/* thead.map_thead>tr th:nth-child(1) {
    width: 25% !important;
} */
/* .th_outcome_head { width: 16.6% !important;} */

/* .map_table {
    width: 100% !important;
} */
/* .map_table th.media {
    justify-content: center;
} */

.egmMapPage {
    zoom: 0.95;
}


#main_map {
	zoom: 0.85;
    -ms-zoom: 0.85;
	-webkit-zoom: 0.85;
	transform:  scale(1,1);
	-webkit-transform:  scale(1,1);
	-moz-transform:  scale(1,1);
	transform-origin: left top;
	-moz-transform-origin: left top;	
}
	
#zoom_icon_plus {
		color : #45a09f;
}

.search_dropdown ul li .dropdown1 {
    background-image: none;
}

@media only screen and (max-width: 1025px) {
	
	#main_map {
		zoom: 0.65;		
	}
	
	#zoom_icon_plus {
		color : #45a09f;
    }
}

@media only screen and (max-width: 992px) {
	
	#main_map {
		zoom: 0.55;		
	}
	
	#zoom_icon_plus {
		color : #45a09f;
    }
}


@media (max-width: 575px) {
	
	#main_map {
		zoom: 0.33;		
	}
	
	#zoom_icon_plus {
		color : #45a09f;
    }
	
	
}

